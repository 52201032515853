<template>
  <div>
    <div
      :style="layoutType ? dropZoneStyle : dropZoneStyle_table"
      id="dropzonetest"
      class="droptarget"
      v-if="removeevent !== true"
    ></div>

    <!-- <v-snackbar
      absolute
      v-model="snackbar"
      bottom
      :timeout="timeout"
      class="fixed-dialog"
    >
      <v-card :style="snackbarupload" class="pa-0 text-right">
        <v-card-text class="pa-0">
          <v-btn
            v-if="openclose === true"
            color="white"
            @click="closedetail()"
            icon
            x-small
            fab
          >
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-card-text>
        <v-list class="mt-n3" style="border-radius: 5px">
          <v-list-item dense style="width:680px;">
            <v-list-item-content
              v-if="btnClosedetail === false && percentuploadedallfile !== 100"
              :style="snackbarupload"
            >
              <v-row>
                <v-col cols="4" class="pl-6 text-left">
                  {{ $t("dragdrop.uploading") }}
                </v-col>
                <v-col cols="4">
                  <v-btn
                    style="margin-left:10px;"
                    small
                    @click="detailuploadclick($event)"
                  >
                    <span
                      v-if="count_uploadfail > 0 && check_detailfail === true"
                    >
                      {{ $t("dragdrop.cannotupload") }}&nbsp;{{
                        count_uploadfail
                      }}
                      {{ $t("dragdrop.of") }} {{ entryTotal }}
                      {{ $t("dragdrop.file") }}
                    </span>
                    <span v-else>
                      {{ $t("dragdrop.detail") }}
                    </span>
                  </v-btn>
                </v-col>
                <v-col cols="4" class="text-right">
                  {{ count_upload + "/" + entryTotal }}
                  {{ $t("dragdrop.file") }} ({{
                    !percentuploadedallfile
                      ? 0
                      : parseInt(percentuploadedallfile)
                  }}
                  %)
                </v-col>
              </v-row>
              <v-card-text class="pa-0 mb-0 mt-2">
                <b>
                  <v-icon style="margin-bottom:5px;">folder</v-icon
                  ><span class="pl-1">{{ foldername_upload }}</span
                  >&nbsp;&nbsp;<span class="pl-2"
                    >{{
                      count_upload + " " + $t("dragdrop.of") + " " + entryTotal
                    }}
                    {{ $t("dragdrop.file") }}</span
                  >
                </b>
                <v-btn
                  style="margin-left:10px;"
                  small
                  :loading="loading"
                  :disabled="opencancel || count_merge > 0"
                  color="secondary"
                  @click="(loader = 'loading'), cancel_upload()"
                >
                  {{ $t("dragdrop.cancel") }}
                </v-btn>
              </v-card-text>
            </v-list-item-content>
            <v-list-item-content v-else :style="snackbarupload">
              <v-row>
                <v-col v-if="count_cancel > 0" cols="4" class="pl-6 text-left">
                  {{ $t("dragdrop.cancelupload") }}
                </v-col>
                <v-col v-else cols="4" class="pl-6 text-left">
                  {{ $t("dragdrop.uploadsuccess") }}
                </v-col>
                <v-col cols="4">
                  <v-btn
                    style="margin-left:10px;"
                    small
                    @click="detailuploadclick($event)"
                  >
                    <span
                      v-if="count_uploadfail > 0 && check_detailfail === true"
                    >
                      {{ $t("dragdrop.cannotupload") }}&nbsp;{{
                        count_uploadfail
                      }}
                      {{ $t("dragdrop.of") }} {{ entryTotal }}
                      {{ $t("dragdrop.file") }}
                    </span>
                    <span v-else>
                      {{ $t("dragdrop.detail") }}
                    </span>
                  </v-btn>
                </v-col>
                <v-col cols="4" class="text-right">
                  {{ count_upload + "/" + entryTotal }}
                  {{ $t("dragdrop.file") }} ({{
                    !percentuploadedallfile
                      ? 0
                      : parseInt(percentuploadedallfile)
                  }}
                  %)
                </v-col>
              </v-row>
              <v-card-text class="pa-0 mb-0 mt-2">
                <b>
                  <v-icon style="margin-bottom:5px;">folder</v-icon>
                  <span class="pl-1">{{ foldername_upload }}</span
                  >&nbsp;&nbsp;<span class="pl-2"
                    >{{
                      count_upload + " " + $t("dragdrop.of") + " " + entryTotal
                    }}
                    {{ $t("dragdrop.file") }}</span
                  >
                </b>
                <v-btn
                  style="margin-left:10px;"
                  small
                  :loading="loading"
                  :disabled="opencancel || count_merge > 0"
                  color="secondary"
                  @click="(loader = 'loading'), cancel_upload()"
                >
                  {{ $t("dragdrop.cancel") }}
                </v-btn>
              </v-card-text>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-progress-linear
          v-if="btnClosedetail === false"
          :value="percentfileuploading"
          color="blue-grey"
          height="12"
        >
          <strong>{{ percentfileuploading }}%</strong>
        </v-progress-linear>
      </v-card>
    </v-snackbar> -->
    <v-snackbar
      absolute
      v-model="snackbar"
      bottom
      :timeout="timeout"
      class="fixed-dialog"
      text
      rounded="lg"
    >
      <v-list style="padding-top: 0px; border-radius: 10px 10px 0px 0px;" class="mr-n2">
        <v-toolbar
          :color="color.theme"
          dense
          short
          flat
          fab
          :style="stylesnackbar"
          :class="openlistupload === true ? '' : 'mb-n2'"
        >
          <v-list-item v-if="btnClosedetail === false && percentuploadedallfile !== 100">
            <v-list-item-content class="ml-n2 text-left">
              <v-list-item-title style="color: white; font-size: 13px">{{ $t("dragdrop.uploading") }}<br /><span
                  >{{
                    count_upload + " " + $t("dragdrop.of") + " " + entryTotal
                  }}
                  {{ $t("dragdrop.file") }}</span
                ></v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                small
                @click="detailuploadclick($event)"
              >
                <span
                  v-if="count_uploadfail > 0 && check_detailfail === true"
                >
                  {{ $t("dragdrop.cannotupload") }}&nbsp;{{
                    count_uploadfail
                  }}
                  {{ $t("dragdrop.of") }} {{ entryTotal }}
                  {{ $t("dragdrop.file") }}
                </span>
                <span v-else>
                  {{ $t("dragdrop.detail") }}
                </span>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-list-item v-else>
            <v-list-item-content v-if="count_cancel > 0" class="text-left ml-n2">
              <v-list-item-title style="color: white; font-size: 13px">{{ $t("dragdrop.cancelupload") }}</v-list-item-title >
            </v-list-item-content>
            <v-list-item-content v-else class="text-left ml-n2">
              <v-list-item-title style="color: white; font-size: 13px">{{ $t("dragdrop.uploadsuccess") }}</v-list-item-title >
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                small
                @click="detailuploadclick($event)"
              >
                <span
                  v-if="count_uploadfail > 0 && check_detailfail === true"
                >
                  {{ $t("dragdrop.cannotupload") }}&nbsp;{{
                    count_uploadfail
                  }}
                  {{ $t("dragdrop.of") }} {{ entryTotal }}
                  {{ $t("dragdrop.file") }}
                </span>
                <span v-else>
                  {{ $t("dragdrop.detail") }}
                </span>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-btn
            color="white"
            @click="openlistupload = !openlistupload"
            icon
            x-small
            fab
          >
            <v-icon v-if="openlistupload === true">mdi-arrow-down-drop-circle-outline</v-icon>
            <v-icon v-else>mdi-arrow-up-drop-circle-outline</v-icon>
          </v-btn>
          <v-btn
            :disabled="openclose === true ? false : true"
            color="white"
            @click="closedetail()"
            icon
            x-small
            fab
          >
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div v-if="openlistupload === true" style="max-height:250px; height:auto; width:470px; overflow-y: auto">
          <v-list-item class="mt-n1 mb-n5">
            <v-spacer></v-spacer>
            <v-list-item-action>
              <v-btn
               small
               outlined
               :loading="loading"
               :disabled="length_processing === showfileupload.length"
               :color="color.theme"
               @click="(loader = 'loading'), cancel_upload()"
              >
                {{ $t("dragdrop.cancel") }}
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-list-item class="pb-n4" v-for="(item, i) in showfileupload" :key="i" dense>
            <v-list-item-content>
              <v-list-item-title v-if="item.name" class="mt-1">
                <v-icon :color="item.icon[1]" style="margin-bottom:5px;">{{ item.icon[0] }}</v-icon>
                <span class="pl-1">{{ item.name }}</span> <br/> 
                <span v-if="item.copy_paste && item.status.code === 'start'" class="ml-7"> ({{ $t('copyandpaste.copying_word') }}) </span>
                <span v-else-if="item.copy_paste && item.status.code === 'success'" class="ml-7"> ({{ $t('copyandpaste.copied_success') }}) </span>
              </v-list-item-title>
              <v-list-item-title v-else class="mt-1">
                <span class="pl-1">{{ $t("uploadfile.loadingfile") }}</span>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="text-center">
              <v-btn
                fab
                small
                icon
                v-if="item.status.code !== 'start' && item.status.code !== 'Uploading'"
                style="cursor: context-menu"
              >
                <v-progress-circular
                  :rotate="90"
                  :size="30"
                  :width="3"
                  :value="100"
                  :color="item.color"
                >
                  <v-icon v-if="item.status.code === 'success'">mdi-check</v-icon>
                  <v-icon v-else-if="item.status.code === 'cancel' || item.status.code === 'fail'">mdi-close</v-icon>
                  <v-icon v-else>mdi-exclamation</v-icon>
                </v-progress-circular>
              </v-btn>
              <v-hover v-else v-slot="{ hover }">
                <v-btn
                  fab
                  small
                  icon
                  @click="(loader = 'loading'), cancel_upload_one_item(item)"
                >
                  <v-progress-circular  
                    :rotate="90"
                    :size="30"
                    :width="3"
                    :value="!item.value
                      ? 0
                      : parseInt(item.value)"
                    :color="hover ? 'error' : color.theme"
                  >
                    <span v-if="!hover">
                      {{
                        !item.value
                          ? 0
                          : parseInt(item.value)
                      }}
                    </span>
                    <v-icon
                      v-else
                      color="error"
                    >
                      mdi-close
                    </v-icon>
                  </v-progress-circular>
                </v-btn>
              </v-hover>
            </v-list-item-action>
          </v-list-item>
          <v-list-item v-if="showfileupload.length <= 0">
            <v-list-item-title class="mt-1">
              <span class="pl-1">{{ $t("uploadfile.loadingfile") }}</span>
            </v-list-item-title>
          </v-list-item>
        </div>
      </v-list>
    </v-snackbar>
    <dialogalertduplicatefile
      :show="openalertduplicatefile"
      :listfileduplicate="listfileduplicate"
      @skip="setStatusFileDuplicate"
      @replace="setStatusFileDuplicate"
      @copy="setStatusFileDuplicate"
    ></dialogalertduplicatefile>
    <detailupload
      :show="opendetailupload"
      :AreaX="x"
      :AreaY="y"
      :dataupload="showfileupload"
      :listupload="listDataUpload"
      @closedetailupload="closedetail()"
      @hidedetailupload="hidedetail()"
      @cancel="cancel_upload()"
      @canceloneitem="cancel_upload_one_item"
      :datauploading="datauploading"
      :btnclose="btnClosedetail"
      :length_processing="length_processing"
      @loader="loader = 'loading'"
    ></detailupload>
    <!-- <detailuploadfail
        :show="opendetailuploadfail"
        :AreaX="x"
        :AreaY="y"
        :dataupload="showfileupload"
        :listupload="listDataUpload"
        @closedetailupload="closedetail()"
        @hidedetailupload="hidedetail()"
        :datauploading="datauploading"
        :btnclose="btnClosedetail"
      ></detailuploadfail> -->
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import axios, { CancelToken } from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { validationMixin } from "vuelidate";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import gbfsizeDataConverter from "@/globalFunctions/sizeDataConverter.js";
import gb_setIconFiles from "@/globalFunctions/setIconFiles";
import { v4 as uuidv4 } from "uuid";
const detailupload = () => import("@/components/optional/dialog-detailupload");
const dialogalertduplicatefile = () =>
  import("@/components/optional/dialog-alert-duplicate-file.vue");

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  mixins: [validationMixin],
  props: [
    "removeevent",
    "addevent",
    "departmentid",
    "rootfolder",
    "layoutType",
    "heightCard",
    "opendragselected",
    "filemultipledownload",
    "currentfolderid",
    "arrayfileidselect",
    "rootfile",
    "copyinfor" // NEW GENEREATION
  ],
  components: { detailupload, dialogalertduplicatefile },
  data: function() {
    return {
      toFolder_id: "",
      isFromCanvas: false,
      isCannotUpload: true,
      parentFolder_drop: "",
      count_cancel: 0,
      openclose: false,
      opencancel: true,
      percentfileuploading: 0,
      check_detailfail: true,
      percentuploadfolder: 0,
      foldername_upload: "",
      loopsuccess: false,
      checkfail: true,
      opendetailuploadfail: false,
      timeout: -1,
      overmaxsize: false,
      upfile_merge: false,
      check_upload_merge: false,
      upload_chunk: false,
      upload_na: true,
      myuuid: "",
      loop: 0,
      part_per_send: 1,
      datauploading_name: "",
      // count_upload: 0,
      // count_uploadfail: 0,
      entryTotal: 0,
      objIndexAll: 0,
      cancelID: 0,
      btnClosedetail: false,
      countKeyfolder: 0,
      datauploading: {},
      showfileupload: [],
      opendetailupload: false,
      listUpload: [],
      snackbar: false,
      listDataUpload: [],
      listDataUploadfail: [],
      checkCC: false,
      checkCCFolder: false,
      parentfolderDD: "",
      parentfolderDDfile: "",
      re1: "",
      checkpath: "",
      folderupload: [],
      rootname: "",
      totalcountt: 0,
      rootFolder: "",
      count: 0,
      count1: 0,
      failcount: 0,
      total: 0,
      x: 0,
      y: 0,
      loader: null,
      loading: false,
      tempDatacreateupload: [],
      source: "",
      listfileduplicate: [],
      openalertduplicatefile: false,
      fileduplicate: "",
      listpathfolderduplicate: [],
      listfileinfolderduplicate: [],
      checkduplicatechunk: false,
      checkfirstloopchoice: false,
      checkcurrentupload: false,
      loopcheckupload: "",
      stacksizefile: 0,
      remain_storage: 0,
      count_merge: 0,
      openlistupload: true,
      checkcancelone: false,
      count_upload_duplicate: 0,
      datachunkwaiting: [],
      chunkwaiting: [],
      checkopenchunk: false,
      conutindexupload: 0,
      checkupfolder: false,
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color", "role_level"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataCheckPackage",
      "dataRemainStorage",
    ]),
    percentuploadedallfile() {
      return (
        this.showfileupload.reduce((a, b) => {
          let x = b["value"] === 500 ? 100 : b["value"];
          return a + x;
        }, 0) / this.showfileupload.length ||
        this.tempDatacreateupload.reduce((a, b) => {
          let x = b["value"] === 500 ? 100 : b["value"];
          return a + x;
        }, 0) / this.tempDatacreateupload.length
      );
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    snackbarupload() {
      return "font-size: 15px; font-weight: 400; border-radius: 5px;";
    },
    dropZoneStyle() {
      return `cursor: default;
              zIndex: 1;
              borderRadius: 6px;
              `;
      // background: ${this.hexToRGB(this.color.theme)}  zIndex: 1`;
    },
    dropZoneStyle_table() {
      return `cursor: default;
              borderRadius: 6px;`;
    },
    stylesnackbar() {
      if (this.openlistupload === true) {
        return "border-radius: 10px 10px 0px 0px; width:470px;"
      } else {
        return "border-radius: 10px 10px 10px 10px; width:470px;"
      }
    },
    length_processing() {
      return (
        this.showfileupload.filter((item) => {
          return (
            item.status.code === "fail" ||
            item.status.code === "success" ||
            item.status.code === "duplicate" ||
            item.status.code === "skip" ||
            item.status.code === "cancel"
          );
        }).length
      );
    },
    count_uploadfail() {
      return (
        this.showfileupload.filter((item) => {
          return (
            item.status.code === "fail" ||
            item.status.code === "cancel"
          );
        }).length
      );
    },
    length_uploading() {
      return (
        this.showfileupload
          .filter((item) => {
            return item.status.code === "Uploading";
          })
          .concat(
            this.chunkwaiting.filter((item) => {
              return item.status === "Uploading";
            })
          ) ||
        0
      );
    },
    count_upload() {
      return (
        this.showfileupload.filter((item) => {
          return (
            item.status.code !== "Uploading" &&
            item.status.code !== "start"
          );
        }).length
      );
    },
    count_success() {
       return (
        this.showfileupload.filter((item) => {
          return (
            item.status.code === "success"
          );
        }).length
      );
    },
    // จำนวนของอัปไม่สำเร็จและยกเลิก
    count_duplicate() {
      return (
        this.showfileupload.filter((item) => {
          return (
            item.status.code === "duplicate"
          );
        }).length
      );
    },
    // จำนวนที่ไม่ใช่ start
    shower_uploading() {
      return (
        this.showfileupload.filter((item) => {
          return (
            item.status.code === "start" ||
            item.status.code === "Uploading"
          );
        }).slice(0,5)
      );
    },
  },
  watch: {
    show: function(val) {
      this.sendParentfolder();
    },
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 1500);

      this.loader = null;
    },
    length_processing() {
      if (this.showfileupload.length !== 0) {
        if (
          this.percentuploadedallfile === 100 &&
          this.listfileduplicate.length === 0 &&
          this.openclose === false
        ) {
          setTimeout(() => {
            this.fn_close_snackbar();
          }, 1500);
        }
      }
    },
    copyinfor:  {
      immediate: true,
      deep: true,
      handler(value) {
        try{
          if(value.tag_procress){
          if(value.status === 'triggle-copy/paste'){
            this.toFolder_id = value.tofolder_
            this.fn_manage_files_copy(value.array_file_id).then((files_response) => {
              console.log("Free palestine ", files_response)
              this.$emit("close_snackbar_copypaste")
              if(files_response.length >= 1 ){
                this.fn_start_copyandpaste_file(files_response)
              } else {
                navigator.clipboard.writeText("")
                return
              }
            })
          // UPDATE TAG PROCRESS
          this.$emit("update_tagprocress")
          // ใช้งานฟังค์ชั่น copy paste
          }else {
            return 
          }
          } else {
            return
          }
        } catch (err) {
          return
        }
      }
    },
    length_uploading(val) {
      if (this.snackbar === true) {
        //  && this.statusuploading === true
        if (this.showfileupload.length > 0) {
          if (this.showfileupload.length > this.conutindexupload) {
            if (this.checkopenchunk === false) {
              let lastindex = this.conutindexupload;
              if (val.length < 5 && val.length >= 0) {
                if (this.showfileupload[lastindex]) {
                  if (val.length < 5) {
                    if (this.checkupfolder !== true) {
                      if (this.showfileupload[lastindex]["type"] === "folder") {
                        this.checkupfolder = true;
                      }
                      this.checkUploading(lastindex);
                      this.conutindexupload += 1;
                    }
                  }
                }
              }
            } else {
              console.log("this.checkopenchunk", this.checkopenchunk);
              let lastindex = this.datachunkwaiting.id;
              if (val.length < 5) {
                if (
                  this.showfileupload[lastindex]["allchunk"].length >
                  this.showfileupload[lastindex]["numchunk"] + 1
                ) {
                  if (this.showfileupload[lastindex]["allchunk"].length > 0) {
                    this.showfileupload[lastindex]["numchunk"] += 1;
                    this.fnuploadChunks3(
                      this.showfileupload[lastindex]["datafile"],
                      lastindex,
                      this.showfileupload[lastindex]["numchunk"],
                      ""
                    );
                  }
                } else {
                  this.checkopenchunk = false;
                }
              }
            }
          } else {
            if (this.listfileduplicate.length > 0 && val.length === 0) {
              this.openalertduplicatefile = true;
            } else if (val.length === 0) {
              this.checkcurrentupload = false;
            }
            if (this.chunkwaiting.length > 0) {
              if (
                this.chunkwaiting.filter((item) => item.status === "Uploading")
                  .length > 0 ||
                this.chunkwaiting.filter((item) => item.status === "start")
                  .length > 0
              ) {
                let lastindex = this.datachunkwaiting.id;
                if (val.length < 5) {
                  if (
                    this.showfileupload[lastindex]["allchunk"].length >
                    this.showfileupload[lastindex]["numchunk"] + 1
                  ) {
                    if (this.showfileupload[lastindex]["allchunk"].length > 0) {
                      this.showfileupload[lastindex]["numchunk"] += 1;
                      this.fnuploadChunks3(
                        this.showfileupload[lastindex]["datafile"],
                        lastindex,
                        this.showfileupload[lastindex]["numchunk"],
                        ""
                      );
                    }
                  } else {
                    this.checkopenchunk = false;
                  }
                }
              }
            }
          }
        }
      } else {
        this.conutindexupload = 0;
      }
    },
  },

  created() {
    this.removeEvent();
    this.addEvent();
  },
  methods: {
    async fn_manage_files_copy (items) {
      var files_infor_created = []
      await this.fn_check_infor_copy(items).then((response) => {
        if(response.status === "FILE_READY"){
          let clean_files_item = []
          console.log("File ready here ", response.files_infor)
          response.files_infor.forEach((itemFor) => {
            if(itemFor != null){
              let obj_clean = {
              file_id: itemFor.id,
              file_name: itemFor.name,
              file_type: itemFor.data_type,
              file_size: itemFor.size
              }
              clean_files_item.push(obj_clean)
            }
          })
          files_infor_created = clean_files_item
        } else {
          return 
        }
      })
      return files_infor_created
    },
    async fn_check_infor_copy (items_file) {
      return new Promise(async (resolve, reject) => {
        let count_len = items_file.length
        let count_while = 0
        let bolltest = true 
        var check_list_infor = []
        var check_payload_error = []
        while(count_while < count_len){
          check_list_infor.push(items_file[count_while])
          let payload = {
            data_id: items_file[count_while],
            data_type: "file",
            account_id: this.dataAccountId,
          };
          check_payload_error.push(payload)
          let auth = await gbfGenerate.generateToken();
          this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_API +  "/api/info_data",
            payload,
            { headers: { Authorization: auth.code } }
          ).then((response) => {
            if (response.data.status === "OK") {
              // ทำการหาตำแหน่ง array ของ file_id นี้
              var dataObj = response.data.result;
              let findIndexFiles = items_file.findIndex((itemFiles) => dataObj.id === itemFiles)
              //เมื่อเจอตำแหน่ง id ของไฟลนั้นแล้ว ให้ทำการ assign เข้าไปในตำแหน่งนั้น
              check_list_infor[findIndexFiles] = dataObj
              if(check_list_infor.every((checkType) => typeof(checkType) === "object")){
                resolve({status: "FILE_READY", files_infor: check_list_infor })
              }
            } else {
              let findIndexFiles = items_file.findIndex((itemFiles) => itemFiles === payload.data_id)
              check_list_infor[findIndexFiles] = null
              if(check_list_infor.every((checkType) => typeof(checkType) === "object")){
                resolve({status: "FILE_READY", files_infor: check_list_infor })
              }
            }
          }).catch((err) => {
            console.log("Error Some ", err);
          })
          
          count_while++
        }
        
      })
    },
    fn_copyandpaste_file(item_infor) {
      this.snackbar = true
    },
    // HEX to rgba
    convertColor(hexcode) {
      let hex = hexcode;
      let opacity = "0.2";
      // Convert each hex character pair into an integer
      let red = parseInt(hex.substring(1, 3), 16);
      let green = parseInt(hex.substring(3, 5), 16);
      let blue = parseInt(hex.substring(5, 7), 16);

      // Concatenate these codes into proper RGBA format
      let rgba = ` rgba(${red}, ${green}, ${blue}, ${opacity})`;
      return rgba;
    },
    // ดักการรีโหลด
    forceDestroyEvent(status) {
      if (this.openalertduplicatefile === false) {
        if (status === true) {
          window.addEventListener("beforeunload", this.removeUser, true);
        } else {
          window.removeEventListener("beforeunload", this.removeUser, true);
        }
      }
    },
    // event สำหรับการรีโหลดหน้า
    removeUser(event) {
      event.returnValue = "Uploading folder";
    },
    // ฟังชันก์ยกเลิกการอัปโหลด
    cancel_upload() {
      this.count_cancel = 1;
      this.openclose = true;
      // this.fn_close_snackbar();
      this.btnClosedetail = true;
      this.tempDatacreateupload = [...this.showfileupload];
      this.opencancel = true;
      for (let i = 0; i < this.showfileupload.length; i++) {
        if (this.showfileupload[i]["status"]["code"] === "start" || this.showfileupload[i]["status"]["code"] === "Uploading") {
          if (this.showfileupload[i]["canceltoken"] !== "") {
            this.showfileupload[i]["canceltoken"].cancel();
          }
          this.showfileupload[i]["status"] = {
            code: "cancel",
            message: "dragdrop.cancelupload",
            devMessage: "",
          };
          this.showfileupload[i]["color"] = "error"
          if (this.showfileupload[i]["listxhr"].length > 0) {
            const xhrList = this.showfileupload[i]["listxhr"];
            xhrList.forEach(xhr => xhr.abort());
          }
        }
      }
    },
    cancel_upload_one_item(item) {
      // this.checkcancelone = true;
      item.checkcancelone = true;
      if (item.canceltoken !== "") {
        item.canceltoken.cancel();
      }
      item["status"] = {
        code: "cancel",
        message: "dragdrop.cancelupload",
        devMessage: "",
      };
      item["color"] = "error";
      if (item["listxhr"].length > 0) {
        const xhrList = item["listxhr"];
        xhrList.forEach(xhr => xhr.abort());
      }
      if (item.size > 1073741824) {
        let itemchunk = this.chunkwaiting.filter(el => el.id = item.id)
        for (let i = 0; i < itemchunk.length; i++) {
          let indexwatingchunk = this.chunkwaiting.indexOf(itemchunk[i]);
          this.chunkwaiting[indexwatingchunk].status === "cancel";
          if (itemchunk.length > i + 1) {
            if (this.chunkwaiting.filter(check => check.status === "Uploading").length === 0) {
              this.checkopenchunk = false;
              this.datachunkwaiting = [];
            }
          }
        }
      }
      if (this.length_processing === this.showfileupload.length) {
        this.btnClosedetail = true;
        this.opencancel = true;
        this.openclose = true;
      }
      // this.count_uploadfail++
    },
    //   dragenter(e) {
    //     document.querySelector("#dropzone").style.visibility = "";
    //     document.querySelector("#dropzone").style.opacity = 1;
    //     document.querySelector("#textnode").style.fontSize = "48px";
    //   },
    //   dragleave(e) {
    //     e.preventDefault();
    //     e.stopPropagation();
    //     document.querySelector("#dropzone").style.visibility = "hidden";
    //     document.querySelector("#dropzone").style.opacity = 0;
    //     document.querySelector("#textnode").style.fontSize = "42px";
    //   },
    //   dragover(e) {
    //     e.preventDefault();
    //     e.stopPropagation();
    //     document.querySelector("#dropzone").style.visibility = "";
    //     document.querySelector("#dropzone").style.opacity = 1;
    //     document.querySelector("#textnode").style.fontSize = "48px";
    //   },
    dragenter(e) {
      var items = e;
      // console.log("drag grid eventder", items);
      if (this.layoutType) {
        //GRID
        if (
          (e.target.className === "droptarget" ||
          e.target.className === "canvas-drop-card" ||
          e.target.classList.contains("cardItemFolder"))
        ) {
          if (this.opendragselected === true) {
            this.$emit("status_opendragselected");
            this.$emit("status_opendragzone");
          } else {
            this.$emit("status_opendragzone");
            document.querySelector(
              "#dropzonetest"
            ).style.border = `3px solid ${this.color.theme}`;
            document.querySelector(
              "#dropzonetest"
            ).style.background = this.convertColor(this.color.theme);
          }
        }
      } else {
        // console.log("event enter ", e);
        // console.log("event data-table");
        if (
          // e.target.className === "droptarget" ||
          e.target.classList.contains("cardDataTable") ||
          e.target.parentElement.classList.contains("cardDataTable") ||
          e.target.parentElement.classList.contains("v-slide-group__wrapper")
        ) {
          console.log("parent node ", e.target.parentNode.id);
          this.isFromCanvas = false;
          this.$emit("fromBlamkCanvas", this.isFromCanvas);
          this.$emit("opendragzone_table", true);
          if (this.opendragselected === true) {
            this.$emit("opendragzone_table", true);
            this.$emit("status_opendragselected");
          }
        } else if (e.target.className === "droptarget") {
          console.log("is Canvas true");
          this.isFromCanvas = true;
          this.$emit("opendragzone_table", true);
          this.$emit("fromBlamkCanvas", this.isFromCanvas);
        } else if (e.target.firstChild !== "" && e.target.firstChild !== null) {
          if (e.target.firstChild.className !== null && e.target.firstChild.className !== undefined) {
            if (e.target.firstChild.className.includes("cardDataTable") === true) {
              this.isFromCanvas = false;
              this.$emit("fromBlamkCanvas", this.isFromCanvas);
              this.$emit("opendragzone_table", true);
              if (this.opendragselected === true) {
                this.$emit("opendragzone_table", true);
                this.$emit("status_opendragselected");
              }
            } else {
              this.isCannotUpload = false;
              this.$emit('opendragzone_table', false);
              this.$emit("close_cloudicon");
            }
          } else {
            this.isCannotUpload = false;
            this.$emit('opendragzone_table', false);
            this.$emit("close_cloudicon");
          }
        } else {
          this.isCannotUpload = false;
          this.$emit('opendragzone_table', false);
          this.$emit("close_cloudicon");
        }
      }
    },
    dragleave(e) {
      e.preventDefault();
      e.stopPropagation();
      if (this.layoutType === true && this.opendragselected === true) {
        if (e.target.className === "droptarget") {
          console.log("event leave ", e);
          try {
            if (
              e.fromElement.className === "canvas-drop-card" ||
              e.fromElement.classList.contains("cardItemFolder") ||
              e.fromElement.classList.contains("position_icon_dragdrop")
            ) {
              return;
            }
              this.isCannotUpload = true
              this.$emit("status_closedragzone")
              document.querySelector("#dropzonetest").style.border = "";
              document.querySelector("#dropzonetest").style.background = "" 
          } catch (err) {
            this.isCannotUpload = true;
            this.$emit("status_closedragzone");
            document.querySelector("#dropzonetest").style.border = "";
            document.querySelector("#dropzonetest").style.background = "";
          }
        } else {
          console.log("from Element else", e.fromElement)
          if(e.fromElement === null){
            this.isCannotUpload = true
            this.$emit("status_closedragzone")
            document.querySelector("#dropzonetest").style.border = "";
            document.querySelector("#dropzonetest").style.background = ""
          }
        }
      } else if (this.layoutType === false && this.opendragselected === true) {
        // this.$emit("opendragzone_table", false);
        // this.$emit("status_closedragselected");
      } else {
        if(this.layoutType){
          if(e.target.className === "droptarget"){
          console.log("event leave ",e)
          try{
            if(
              e.fromElement.className === "canvas-drop-card" || 
              e.fromElement.classList.contains("cardItemFolder") ||
              e.fromElement.classList.contains("position_icon_dragdrop") ){
              return
            }
            
            this.isCannotUpload = true
            this.$emit("status_closedragzone")
            document.querySelector("#dropzonetest").style.border = "";
            document.querySelector("#dropzonetest").style.background = ""
            //document.querySelector("#textnode").style.fontSize = "42px";
          } catch (err) { // เมื่อ pointer ตัวอืนๆ
            this.isCannotUpload = true
            this.$emit("status_closedragzone")
            document.querySelector("#dropzonetest").style.border = "";
            document.querySelector("#dropzonetest").style.background = ""
          }
          
          } else {
              console.log("from Element else", e.fromElement)
              if(e.fromElement === null){
                this.isCannotUpload = true
                this.$emit("status_closedragzone")
                document.querySelector("#dropzonetest").style.border = "";
                document.querySelector("#dropzonetest").style.background = ""
              }
              
          }
        } else {
          console.log("event leave ",e)
          if(e.fromElement === null || e.fromElement === ""){
            console.log("Leave here A")
            this.$emit('opendragzone_table', false)
            this.isCannotUpload = true
          } else if (
            e.fromElement.classList.contains("canvas-data-table") || 
            e.fromElement.classList.contains("v-navigation-drawer__content") ) {
              console.log("Leave here B",this.isFromCanvas)
            this.$emit('opendragzone_table', false)
            this.isCannotUpload = true
            //v-navigation-drawer__content
          }  else {
            return
          }
        }
      }
    },
    dragover(e) {
      e.preventDefault();
      e.stopPropagation();
      let permission;
      if (this.opendragselected === true) {
        permission = ["04", "05", "06"];
      } else {
        permission = ["03","04", "05", "06"]
      }
      if (this.layoutType) {
        //GRID
        if (
          e.target.className === "droptarget" ||
          e.target.className === "canvas-drop-card" ||
          e.target.classList.contains("cardItemFolder")
        ) {
          let prepare_fileId = this.rootfolder.map((item) => item.file_id);
          var to_folder;
          var to_idFolder;
          let findId = prepare_fileId.find(
            (element) => element === e.target.id
          );
          let lock_data_folder = 
              this.rootfolder.filter(
                (item) => item.file_id === e.target.id
              )[0]
          if (!!findId && lock_data_folder.status_lock_data === "N" && permission.includes(lock_data_folder.file_permission_2)) {
            to_folder = this.rootfolder.filter(
              (item) => item.file_id === e.target.id
            )[0].file_name;
            to_idFolder = this.rootfolder.filter(
              (item) => item.file_id === e.target.id
            )[0].file_id;
            this.parentFolder_drop = this.rootfolder.filter(
              (item) => item.file_id === e.target.id
            )[0].file_id;
          } else {
            to_folder = this.$t("toolbar.mydrive");
            this.parentFolder_drop = "";
          }
          // console.log("to_idFolder", to_idFolder);
          // console.log("to_idFolder", to_idFolder);
          if (this.arrayfileidselect.findIndex(item => item === to_idFolder) !== -1) {
            to_folder = this.$t("toolbar.mydrive");
            this.parentFolder_drop = "";
            this.$emit("to_namefolder", to_folder, "");
            this.isCannotUpload = false;
          } else {
            this.$emit("to_namefolder", to_folder, to_idFolder);
            this.isCannotUpload = false;
          }
        } else {
          this.$emit("to_namefolder", to_folder, "");
          this.isCannotUpload = false;
          this.$emit("close_cloudicon");
        }
      } else {
        // console.log("event derrrr", e);
        if (
          e.target.classList.contains("cardDataTable") ||
          e.target.parentElement.classList.contains("cardDataTable") ||
          e.target.className === "droptarget" ||
          e.target.parentElement.classList.contains("v-slide-group__wrapper")
        ) {
          // console.log("event derrrr if");
          let prepare_fileId = this.rootfolder.map((item) => item.file_id);
          var to_folder;
          let findId = prepare_fileId.find(
            (element) => element === e.target.parentNode.id
          );
          if (
            !!findId &&
            this.rootfolder.find((item) => item.file_id === findId)
              .status_lock_data === "N" &&
            permission.includes((this.rootfolder.filter((item) => item.file_id === findId)[0].file_permission_2))
          ) {
            // let dupfilemulti = this.filemultipledownload.find((item) => item.file_id === e.target.parentNode.id);
            let dupfilemulti = this.arrayfileidselect.find((item) => item === e.target.parentNode.id);
            if (this.opendragselected === true && dupfilemulti !== undefined ) {
              to_folder = this.$t("toolbar.mydrive");
            } else {
              to_folder = this.rootfolder.filter(
                (item) => item.file_id === e.target.parentNode.id
              )[0].file_name;
              this.parentFolder_drop = this.rootfolder.filter(
                (item) => item.file_id === e.target.parentNode.id
              )[0].file_id;
            }
          } else {
            to_folder = this.$t("toolbar.mydrive");
            this.parentFolder_drop = "";
          }
          this.$emit("to_namefolder", to_folder);
          this.isCannotUpload = false;
          
        } else if (e.target.firstChild !== "" && e.target.firstChild !== null && e.target.firstChild !== undefined) {
          if (e.target.firstChild.className !== null && e.target.firstChild.className !== undefined) {
            if (e.target.firstChild.className.includes("cardDataTable") === true){
              let prepare_fileId = this.rootfolder.map((item) => item.file_id);
              var to_folder;
              let findId = prepare_fileId.find(
                (element) => element === e.target.parentNode.id
              );
              if (
                !!findId &&
                this.rootfolder.find((item) => item.file_id === findId)
                  .status_lock_data === "N"
              ) {
                to_folder = this.rootfolder.filter(
                  (item) => item.file_id === e.target.parentNode.id
                )[0].file_name;
                this.parentFolder_drop = this.rootfolder.filter(
                  (item) => item.file_id === e.target.parentNode.id
                )[0].file_id;
              } else {
                to_folder = this.$t("toolbar.mydrive");
                this.parentFolder_drop = "";
              }
              this.$emit("to_namefolder", to_folder);
              this.isCannotUpload = false;
            } else {
              // console.log("event derrrr else");
              this.$emit("to_namefolder", to_folder, "");
              this.isCannotUpload = false;
              this.$emit('opendragzone_table', false);
            }
          } else {
            this.$emit("to_namefolder", to_folder, "");
            this.isCannotUpload = false;
            this.$emit('opendragzone_table', false);
          }
        } else {
          console.log("event derrrr else");
          this.$emit("to_namefolder", to_folder, "");
          this.isCannotUpload = false;
          this.$emit('opendragzone_table', false);
        }
      }
    },
    // ฟังชันก์ลบ event dragdrop download
    removeEvent() {
      var self = this;
      self.closeDragDrop = true;
      window.removeEventListener("dragenter", self.dragenter, true);
      window.removeEventListener("dragleave", self.dragleave, true);
      window.removeEventListener("dragover", self.dragover, true);
      window.removeEventListener("drop", self.drop, true);
    },
    // ฟังชันก์เพิ่ม event dragdrop download
    addEvent() {
      if (this.resolutionScreen >= 500) {
        var self = this;
        self.closeDragDrop = false;
        window.addEventListener("dragenter", self.dragenter, true);
        window.addEventListener("dragleave", self.dragleave, true);
        window.addEventListener("dragover", self.dragover, true);
        window.addEventListener("drop", self.drop, true);
      }
    },
    // ฟังชันก์เคลียร์ข้อมูล
    fn_clear_before_upload() {
      this.checkCCFolder = false;
      this.checkCC = false;
      this.btnClosedetail = false;
      this.total = 0;
      this.count = 0;
      this.failcount = 0;
      // this.count_upload = 0;
      // this.count_uploadfail = 0;
      this.percentuploadfolder = 0;
      this.folderupload = [];
      this.datauploading = "";
      // this.showfileupload = [];
      this.percentfileuploading = 0;
      this.count_cancel = 0;
      this.tempDatacreateupload = [];
      this.checkfirstloopchoice = false;
      this.listfileduplicate = [];
      this.listpathfolderduplicate = [];
      this.listfileinfolderduplicate = [];
      this.stacksizefile = 0;
      // this.entryTotal = 0;
      this.total = 0;
      this.count_merge = 0;
      this.remain_storage = 0;
      this.datachunkwaiting = [];
      this.chunkwaiting = [];
      this.checkopenchunk = false;
      // this.conutindexupload = 0;
      this.checkupfolder = false;
    },
    async movefile(event) {
      let datalist = [];
      // for (let i = 0; i < this.filemultipledownload.length; i++) {
      //   if (this.filemultipledownload[i].file_status !== "M" && (this.filemultipledownload[i].file_permission_2 === "04" || this.filemultipledownload[i].file_permission_2 === "05" || this.filemultipledownload[i].file_permission_2 === "06")) {
      //     datalist.push({
      //       data_id: this.filemultipledownload[i].file_id,
      //       data_type: this.filemultipledownload[i].file_type,
      //     });
      //   }
      // }
      console.log("this.arrayfileidselect", this.arrayfileidselect);
      for (let i = 0; i < this.arrayfileidselect.length; i++) {
        let file = this.rootfile.filter(item => item.file_id === this.arrayfileidselect[i]);
        if (file !== undefined && file !== []) {
          if (file[0].file_status !== "M" && (file[0].file_permission_2 === "04" || file[0].file_permission_2 === "05" || file[0].file_permission_2 === "06")) {
            datalist.push({
              data_id: file[0].file_id,
              data_type: file[0].file_type,
            });
          }
        }
      }
      let jsonObject = datalist.map(JSON.stringify);
      let uniqueSet = new Set(jsonObject);
      let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
      datalist = [...uniqueArray ];
      let parentfolder = await this.sendParentfolder();
      let targetfolderid = event.target.id;
      console.log("targetfolderid", targetfolderid);
      let permission = ["04", "05", "06"]
      if (this.rootfolder.find((itemId) => itemId.file_id === targetfolderid) !== undefined) {
        if (permission.includes(this.rootfolder.filter((item) => item.file_id === event.target.id)[0].file_permission_2)) {
          if (datalist.find(x => x.data_id === targetfolderid) === undefined && targetfolderid !== parentfolder && targetfolderid !== "" && this.rootfolder.find((itemId) => itemId.file_id === targetfolderid).status_lock_data !== 'Y') {
            let adminstatus = "False";
            if (this.role_level == "True") {
              adminstatus = "True";
            } else {
              adminstatus = "False";
            }
            let payload = {
              user_id: this.dataUsername,
              account_id: this.dataAccountId,
              data_list: datalist,
              target_folder_id: targetfolderid,
              current_folder_id: parentfolder,
              admin_status: adminstatus,
            };
            let auth = await gbfGenerate.generateToken();
            console.log("drag grid drop if");
            this.axios
              .post(process.env.VUE_APP_SERVICE_UPDATE_MULTIPLES + "/api/multiple_move_data", payload, {
                headers: { Authorization: auth.code },
              })
              .then((response) => {
                if (response.data.status === "OK") {
                  if (this.arrayfileidselect.length !== datalist.length) {
                    if (this.arrayfileidselect.length === 1) {
                      Toast.fire({
                        icon: "error",
                        title: this.$t("movefiles.cannotmovefile"),
                      });
                    } else {
                      let numitemnomove = this.arrayfileidselect.length - datalist.length;
                      Toast.fire({
                        icon: "error",
                        title: this.$t("multimovefiles.filefoldermomove1") + 
                          numitemnomove + 
                          this.$t("multimovefiles.filefoldermomove2"),
                      });
                    }
                  } else {
                    Toast.fire({
                      icon: "success",
                      title: this.$t("movefiles.multisuccess"),
                    });
                  }
                  this.$emit("loadfile");
                  this.$emit("status_closedragselected");
                } else if (response.data.errorCode === "ER405") {
                  Toast.fire({
                    icon: "warning",
                    title: this.$t("movefiles.mutifilefoldernomove"),
                  });
                  if (this.layoutType === true) {
                    this.$emit("status_opendragselected_noclound");
                  }
                } else {
                  Toast.fire({
                    icon: "warning",
                    title: this.$t("movefiles.muticannotmove"),
                  });
                  if (this.layoutType === true) {
                    this.$emit("status_opendragselected_noclound");
                  }
                }
              })
              .catch((error) => {
                console.log("dialog", error);
                Toast.fire({
                  icon: "error",
                  title: this.$t("movefiles.cannotmovefile"),
                });
                if (this.layoutType === true) {
                  this.$emit("status_opendragselected_noclound");
                }
              });
          } else {
            console.log("drag grid drop else");
            if (this.layoutType === true) {
              this.$emit("status_opendragselected_noclound");
            }
            Toast.fire({
              icon: "error",
              title: this.$t("movefiles.lockfilefolder"),
            });
          }
        } else {
          console.log("drag grid drop else");
          if (this.layoutType === true) {
            this.$emit("status_opendragselected_noclound");
          }
          Toast.fire({
            icon: "error",
            title: this.$t("movefiles.permissionnotallow"),
          });
        }
      } else {
        if (this.layoutType === true) {
          this.$emit("status_opendragselected_noclound");
        }
        Toast.fire({
          icon: "error",
          title: this.$t("movefiles.choosetargetallowfolder"),
        });
      }
      this.$emit("status_closedragzone");
      this.$emit("close_cloudicon");
    },
    // ฟังชันก์อ่านไฟล์/โฟลเดอร์ที่ drop มา
    async drop(e) {
      console.log("drop grid table", e, this.isCannotUpload);
      console.log("this.isCannotUpload", this.isCannotUpload);
      if (this.isCannotUpload === true) {
        return;
      }
      if (this.opendragselected === true && this.layoutType === true) {
        await this.movefile(e)
      } else if (this.opendragselected === true && this.layoutType === false) {
        this.$emit("close_cloudicon");
        await this.movefile(e);
      } else if (this.removeevent !== true && (e.target.id === "dropzonetest" || e.target.classList.contains("cardDataTable") ||
          e.target.parentElement.classList.contains("cardDataTable") || e.target.classList.contains("canvas-drop-card")  ||
          e.target.parentElement.classList.contains("v-slide-group__wrapper"))) {
        // เช็ค props removeevent ไม่เท่ากับ true ให้ drop ไฟล์ได้
        e.preventDefault();
        e.stopPropagation();
        this.openclose = false;
        if (this.layoutType) {
          this.$emit("close_cloudicon");
          // document.querySelector("#dropzonetest").style.opacity = 0;
          document.querySelector("#dropzonetest").style.border = "";
          document.querySelector("#dropzonetest").style.background = ""
        } else {
          this.$emit("opendragzone_table", false);
        }
        //   document.querySelector("#dropzone").style.visibility = "hidden";
        //   document.querySelector("#dropzone").style.opacity = 0;
        //   document.querySelector("#textnode").style.fontSize = "42px";
        if (this.showfileupload.length === 0) {
          this.fn_clear_before_upload(); // เคลียร์ค่าเพื่อการอัปโหลด
        } else {
          this.btnClosedetail = false;
        }
        var items = e.dataTransfer.items; // รับข้อมูลของไฟล์/โฟลเดอร์ให้อยู่ในรูปแบบ DataTransferItemList
        let entries = [];
        // วน DataTransferItemList เพื่อแปลงไฟล์/โฟลเดอร์ให้อยู่ในรูปแบบ File/DirectoryEntry entries
        for (var i = 0; i < items.length; ++i) {
          if (items[i].webkitGetAsEntry() !== null) {
            entries.push(items[i].webkitGetAsEntry());
          }
        }
        // เช็คว่ามีไฟล์/โฟลเดอร์หรือไม่ เพื่อป้องกันการดรอปสิ่งอื่นที่ไม่ใช่ไฟล์/โฟลเดอร์
        if (entries.length > 0) {
          this.snackbar = true;
          // this.$emit("snackbarupload");
          await this.forceDestroyEvent(true);
          let responsetreefolder;
          // ส่ง list File/DirectoryEntry entries ทั้งหมดเพื่อทำการอ่านไฟล์/โฟลเดอร์
          await this.fn_search_tree_folder(entries).then(async (res) => {
            responsetreefolder = res;
          });
          if (responsetreefolder.status === "read subfolder success") {
            // this.fn_loop_uploadfolder();
            this.entryTotal = this.showfileupload.length;
            this.opencancel = false;
          }
        } else {
          if (this.layoutType) {
            this.$emit("close_cloudicon");
            this.$emit("status_closedragzone")
            document.querySelector("#dropzonetest").style.border = "";
            document.querySelector("#dropzonetest").style.background = "" 
          } else {
            this.$emit("opendragzone_table", false);
          }
        }
      } else if (e.target.firstChild !== "" && e.target.firstChild !== null) {
        if (e.target.firstChild.className !== null && e.target.firstChild.className !== undefined) {
          if (e.target.firstChild.className.includes("cardDataTable") === true) {
            e.preventDefault();
            e.stopPropagation();
            this.openclose = false;
            if (this.layoutType) {
              this.$emit("close_cloudicon");
              // document.querySelector("#dropzonetest").style.opacity = 0;
            } else {
              this.$emit("opendragzone_table", false);
            }
            //   document.querySelector("#dropzone").style.visibility = "hidden";
            //   document.querySelector("#dropzone").style.opacity = 0;
            //   document.querySelector("#textnode").style.fontSize = "42px";
            if (this.showfileupload.length === 0) {
              this.fn_clear_before_upload(); // เคลียร์ค่าเพื่อการอัปโหลด
            } else {
              this.btnClosedetail = false;
            }
            var items = e.dataTransfer.items; // รับข้อมูลของไฟล์/โฟลเดอร์ให้อยู่ในรูปแบบ DataTransferItemList
            let entries = [];
            // วน DataTransferItemList เพื่อแปลงไฟล์/โฟลเดอร์ให้อยู่ในรูปแบบ File/DirectoryEntry entries
            for (var i = 0; i < items.length; ++i) {
              if (items[i].webkitGetAsEntry() !== null) {
                entries.push(items[i].webkitGetAsEntry());
              }
            }
            // เช็คว่ามีไฟล์/โฟลเดอร์หรือไม่ เพื่อป้องกันการดรอปสิ่งอื่นที่ไม่ใช่ไฟล์/โฟลเดอร์
            if (entries.length > 0) {
              this.snackbar = true;
              // this.$emit("snackbarupload");
              await this.forceDestroyEvent(true);
              let responsetreefolder;
              // ส่ง list File/DirectoryEntry entries ทั้งหมดเพื่อทำการอ่านไฟล์/โฟลเดอร์
              await this.fn_search_tree_folder(entries).then(async (res) => {
                responsetreefolder = res;
              });
              if (responsetreefolder.status === "read subfolder success") {
                // this.fn_loop_uploadfolder();
              }
            } else {
              if (this.layoutType) {
                this.$emit("close_cloudicon");
                this.$emit("status_closedragzone")
                document.querySelector("#dropzonetest").style.border = "";
                document.querySelector("#dropzonetest").style.background = "" 
              } else {
                this.$emit("opendragzone_table", false);
              }
            }
          } else {
            console.log("epgpgpepgpe");
            if (this.layoutType) {
              this.$emit("close_cloudicon");
              // document.querySelector("#dropzonetest").style.opacity = 0;
            } else {
              this.$emit("opendragzone_table", false);
            }
          }
        } else {
          if (this.layoutType) {
            this.$emit("close_cloudicon");
            // document.querySelector("#dropzonetest").style.opacity = 0;
          } else {
            this.$emit("opendragzone_table", false);
          }
        }
      } else {
        console.log("remove");
        if (this.layoutType) {
          this.$emit("close_cloudicon");
          // document.querySelector("#dropzonetest").style.opacity = 0;
        } else {
          this.$emit("opendragzone_table", false);
        }
      }
    },
    // ฺBegin copy paste file
    async fn_start_copyandpaste_file (files) {
      // ส่ง list File/DirectoryEntry entries ทั้งหมดเพื่อทำการอ่านไฟล์/โฟลเดอร์
      await this.fn_create_copypaste_file(files).then((res) => {
        if(res.status === 'Success'){
          this.snackbar = true;
          console.log("Showfile upload ", this.showfileupload)
          // this.fn_loop_uploadfolder()
        } else {
          return
        }
      })
      
    },
    // ฟังก์ชัน get ไฟล์/โฟล์เดอร์ทั้งหมด
    async fn_read_all_file_folder(item) {
      return await new Promise(async (resolve, reject) => {
        let entries = [];
        let queue = [];
        queue.push(...item);
        while (queue.length > 0) {
          // ตัด queue ออกมาใช้ทีละไฟล์/โฟลเดอร์ตามลำดับ
          let entry = queue.shift();
          if (entry.isFile) {
            entries.push(entry);
          } else if (entry.isDirectory) {
            entries.push(entry);
            // จะทำการสร้างตัวอ่านของโฟลเดอร์สำหรับอ่านรายการไฟล์/โฟลเดอร์ข้างในโฟลเดอร์
            let directoryReader = entry.createReader();
            // ส่งตัวอ่านไปเพื่อ get รายการไฟล์/โฟลเดอร์ที่อยู่ข้างใน
            let readEntries = await this.traverseFileTree(directoryReader);
            queue.push(...readEntries);
          }
        }
        // this.entryTotal = entries.length;
        setTimeout(() => {
          resolve(entries);
        }, 1000);
      });
    },
    // ฟังก์ชันอ่าน file/folder entries
    async traverseFileTree(directoryReader) {
      try {
        return await new Promise((resolve, reject) => {
          directoryReader.readEntries(resolve, reject);
        });
      } catch (err) {
        console.log(err);
      }
    },
    // ฟังก์ชันอ่านไฟล์/โฟลเดอร์ทั้งหมด
    async fn_search_tree_folder(item) {
      return new Promise(async (resolve, reject) => {
        let entries = await this.fn_read_all_file_folder(item);
        for (let i = 0; i < entries.length; i++) {
          let dataUpload = {};
          // เซต path ให้ data ใหม่
          // โดย path จะไม่มีชื่อของไฟล์/โฟลเดอร์อยู่ใน path
          // เช่น path ไฟล์ /folder/test จะถูกแปลงเป็น /folder/
          var type = entries[i].name.split(/\.(?=[^\.]+$)/);
          let rawpath = entries[i].fullPath.split("/");
          let path = "";
          for (let i = 0; i < rawpath.length - 1; i++) {
            path += rawpath[i] + "/";
          }
          // ปั้นดาต้าใหม่
          dataUpload["name"] = entries[i].name;
          dataUpload["status"] = {
            code: "start",
            message: "dragdrop.uploading",
            devMessage: ""
          }
          dataUpload["value"] = 0;
          if (entries[i].isDirectory) {
            dataUpload["type"] = "folder";
            dataUpload["icon"] = gb_setIconFiles.fn_seticon("folder");
            dataUpload["size"] = 0;
          } else {
            dataUpload["type"] = type[1];
            dataUpload["icon"] = gb_setIconFiles.fn_seticon(
              type[type.length - 1]
            );
            let file = await this.getFile(entries[i]);
            // console.log("uploadchunkja entries[i]", entries[i]);
            // console.log("uploadchunkja file", file);
            dataUpload["size"] = file.size;
          }
          dataUpload["copy_paste"] = false
          dataUpload["canceltoken"] = null;
          dataUpload["id"] = this.countKeyfolder++;
          dataUpload["cancel"] = false;
          dataUpload["folderid"] = null;
          dataUpload["path"] = path;
          dataUpload["data"] = entries[i];
          dataUpload["chunkkey"] = "";
          dataUpload["len_file"] = null;
          dataUpload["file_duplicate"] = "";
          dataUpload["allchunk"] = [];
          dataUpload["numchunk"] = 0;
          dataUpload["parts"] = [];
          dataUpload["listxhr"] = [];
          dataUpload["checkcancelone"] = false;
          dataUpload["color"] = this.color.theme;
          this.showfileupload.push(dataUpload);
        }
        resolve({
          status: "read subfolder success",
          msg: "read subfolder success",
        });
      });
    },
    // ฟังก์ชั่นอ่านไฟล์สำหรับ copy / paste
    fn_create_copypaste_file(files) {
      return new Promise ((resolve, reject) => {
        try{
        //  ทำการค้นหาใน showfileupload ก่อนว่ามีมั้ย
        for (let i = 0; i < files.length; i++) {
          let checkDuplicateFile = []
          this.showfileupload.forEach((itemFor) => {
            if(itemFor.name === files[i].file_name && itemFor.size === parseInt(files[i].file_size)){
              checkDuplicateFile.push(true)
            } else {
              checkDuplicateFile.push(false)
            }
          })
          console.log("Check File dup ", checkDuplicateFile)
          let dataUpload = {};
          dataUpload["name"] = files[i].file_name;
          dataUpload["status"] = {
            code: "start",
            message: "dragdrop.uploading",
            devMessage: ""
          }
          dataUpload["copy_paste"] = true
          dataUpload["value"] = 0;
          dataUpload["type"] = files[i].file_type;
          dataUpload["icon"] = gb_setIconFiles.fn_seticon(files[i].file_type);
          dataUpload["size"] = parseInt(files[i].file_size)
          dataUpload["canceltoken"] = null;
          dataUpload["id"] = files[i].file_id
          dataUpload["cancel"] = false;
          dataUpload["folderid"] = null;
          dataUpload["path"] = "/";
          dataUpload["data"] ="";
          dataUpload["chunkkey"] = "";
          dataUpload["len_file"] = null;
          dataUpload["file_duplicate"] = "";
          dataUpload["allchunk"] = [];
          dataUpload["numchunk"] = 0;
          dataUpload["parts"] = [];
          dataUpload["color"] = this.color.theme;
          this.showfileupload.push(dataUpload);
        }
        resolve({
          status: "Success",
          msg: "Success",
        });  
        }
        catch (err) {
          return
        }
      })
      
    },
    // ฟังก์ชันแปลงไฟล์ Entry เป็นไฟล์
    async getFile(fileEntry) {
      try {
        return await new Promise((resolve, reject) =>
          fileEntry.file(resolve, reject)
        );
      } catch (err) {
        console.log(err);
      }
    },
    // ฟังก์ชันการทำงานตาม action ที่ผู้ใช้เลือกเมื่อไฟล์ซ้ำ
    async setStatusFileDuplicate(selectedduplicate, fileduplicate) {
      let listnewshowupload = selectedduplicate;
      for (let i = 0; i < selectedduplicate.length; i++) {
        let indexduplicate = this.listfileduplicate.indexOf(
          selectedduplicate[i]
        );
        this.listfileduplicate.splice(indexduplicate, 1);
        if (this.listfileduplicate.length === 0) {
          this.openclose = false;
          this.openalertduplicatefile = false;
        }
        selectedduplicate[i]["file_duplicate"] = fileduplicate;
        if (fileduplicate === "") {
          console.log("selectedduplicate[i]", selectedduplicate[i]);
          this.showfileupload.filter((itemFile) => itemFile.copy_paste !== true)[selectedduplicate[i].id]["color"] = "red";
          this.showfileupload.filter((itemFile) => itemFile.copy_paste !== true)[selectedduplicate[i].id]["status"] = {
            code: "cancel",
            message: "dragdrop.cancelupload",
            devMessage: ""
          };
        } else {
          if (this.count_cancel === 1) {
            break;
          } else {
            console.log("เข้าตรงนี้ใช่มั้ย")
            this.showfileupload.filter((itemFile) => itemFile.copy_paste !== true)[selectedduplicate[i].id]["value"] = 0;
            this.showfileupload.filter((itemFile) => itemFile.copy_paste !== true)[selectedduplicate[i].id]["color"] = this.color.theme;
            this.showfileupload.filter((itemFile) => itemFile.copy_paste !== true)[selectedduplicate[i].id]["file_duplicate"] = fileduplicate;
            this.showfileupload.filter((itemFile) => itemFile.copy_paste !== true)[selectedduplicate[i].id]["status"] = {
              code: "start",
              message: "dragdrop.uploading",
              devMessage: ""
            };
            // this.count_upload_duplicate++
            let count_uploading = 0;
            this.btnClosedetail = false;
            if (this.showfileupload.filter((itemFile) => itemFile.copy_paste !== true)[selectedduplicate[i].id]["type"] === "folder") {
              await this.fn_reupload_duplicate(this.showfileupload.filter((itemFile) => itemFile.copy_paste !== true)[selectedduplicate[i].id], count_uploading);
            } else if (count_uploading <= 5) {
              if (this.showfileupload.filter((itemFile) => itemFile.copy_paste !== true)[selectedduplicate[i].id].size > 5368709120) {
                await this.fn_reupload_duplicate(this.showfileupload.filter((itemFile) => itemFile.copy_paste !== true)[selectedduplicate[i].id], count_uploading);
              } else {
                this.fn_reupload_duplicate(this.showfileupload.filter((itemFile) => itemFile.copy_paste !== true)[selectedduplicate[i].id], count_uploading);
              }
              count_uploading++;
            } else {
              await this.fn_reupload_duplicate(this.showfileupload.filter((itemFile) => itemFile.copy_paste !== true)[selectedduplicate[i].id], count_uploading);
              count_uploading = 0;
            }
          }
        }
        if (selectedduplicate[i]["type"] === "folder") {
          let chidren = this.listfileinfolderduplicate.filter(
            (x) => x["mainfolder"] == selectedduplicate[i]["name"]
          );
          if (chidren.length > 0) {
            for (let j = 0; j < chidren.length; j++) {
              chidren[j]["file_duplicate"] = fileduplicate;
              let indexduplicateinfolder = this.listfileinfolderduplicate.indexOf(
                chidren[j]
              );
              this.listfileinfolderduplicate.splice(indexduplicateinfolder, 1);
              if (this.listfileduplicate.length === 0) {
                this.openclose = false;
                this.openalertduplicatefile = false;
              }
              if (fileduplicate === "") {
                this.showfileupload[chidren[j].id]["color"] = "red";
                this.showfileupload[chidren[j].id]["status"] = {
                  code: "cancel",
                  message: "dragdrop.cancelupload",
                  devMessage: ""
                };
              } else {
                if (this.count_cancel === 1) {
                  break;
                } else {
                  this.showfileupload[chidren[j].id]["file_duplicate"] = fileduplicate;
                  this.showfileupload[chidren[j].id]["value"] = 0;
                  this.showfileupload[chidren[j].id]["color"] = this.color.theme;
                  this.showfileupload[chidren[j].id]["status"] = {
                    code: "start",
                    message: "dragdrop.uploading",
                    devMessage: ""
                  };
                  this.btnClosedetail = false;
                  let count_upload_chidren = 0;
                  if (this.showfileupload[chidren[j].id]["type"] === "folder") {
                    await this.fn_reupload_duplicate(this.showfileupload[chidren[j].id], count_upload_chidren);
                  } else if (count_upload_chidren <= 5) {
                    if (this.showfileupload.filter((itemFile) => itemFile.copy_paste !== true)[selectedduplicate[i].id].size > 5368709120) {
                      await this.fn_reupload_duplicate(this.showfileupload[chidren[j].id], count_upload_chidren);
                    } else { 
                      this.fn_reupload_duplicate(this.showfileupload[chidren[j].id], count_upload_chidren);
                    }
                    count_upload_chidren++;
                  } else {
                    await this.fn_reupload_duplicate(this.showfileupload[chidren[j].id], count_upload_chidren);
                    count_upload_chidren = 0;
                  }
                }
              }
            }
          }
          listnewshowupload = listnewshowupload.concat(chidren);
        }
      }
      if (fileduplicate !== "") {
        // รีเซตค่าไปช่วงเริ่มการอัปโหลด
        // if (this.checkfirstloopchoice === false) {
        //   this.showfileupload = listnewshowupload;
        //   this.checkfirstloopchoice = true;
        //   this.count_upload = 0;
        //   this.btnClosedetail = false;
        //   this.total = 0;
        //   this.count = 0;
        //   this.percentuploadfolder = 0;
        //   this.folderupload = [];
        //   this.datauploading = "";
        //   this.entryTotal = this.showfileupload.length;
        //   this.percentfileuploading = 0;
        //   this.tempDatacreateupload = [];
        //   this.listpathfolderduplicate = [];
        // } else {
        //   this.showfileupload = this.showfileupload.concat(listnewshowupload);
        //   this.entryTotal = this.showfileupload.length;
        // }
        

        // if (this.checkcurrentupload === false) {
        //   this.fn_loop_uploadfolder();
        // }
      }

      if (this.listfileduplicate.length === 0) {
        // console.log("this.showfileupload.length", this.showfileupload.length);
        // console.log("this.length_processing", this.length_processing);
        if (fileduplicate === "" && this.length_processing === this.showfileupload.length) {
          this.loopsuccess = true;
          this.openclose = true;
          this.opencancel = true;
        }
        this.openalertduplicatefile = false;
      }
    },
    async fn_reupload_duplicate(item, count_upload) {
      let objIndex = this.showfileupload.findIndex((obj) => obj.id === item.id);
      if (item.type === "folder") {
        await this.fn_uploadfolder(item);
      } else {
        this.showfileupload[objIndex]["numchunk"] = 0;
        this.showfileupload[objIndex]["listxhr"] = [];
        this.showfileupload[objIndex]["part"] = [];
        this.showfileupload[objIndex]["allchunk"] = [];
        this.showfileupload[objIndex]["status"] = {
          code: "Uploading",
          message: "dragdrop.uploading",
          devMessage: ""
        };
        this.fnCheckingFileData(
          this.showfileupload[objIndex],
          objIndex,
          this.showfileupload[objIndex]["numchunk"]
        );
      }
    },
    // ฟังก์ชันเก็บไฟล์/โฟลเดอร์ที่ซ้ำ
    addFolderDuplicate(showfileupload) {
      // payload สำหรับเตรียมการอัปโหลดใหม่ตาม action ผู้ใช้
      let datafileduplicate = {
        name: showfileupload["name"],
        status: showfileupload["status"],
        value: 0,
        type: showfileupload["type"],
        icon: showfileupload["icon"],
        canceltoken: showfileupload["canceltoken"],
        id: showfileupload["id"],
        cancel: showfileupload["cancel"],
        folderid: showfileupload["folderid"],
        path: showfileupload["path"],
        data: showfileupload["data"],
        chunkkey: showfileupload["chunkkey"],
        len_file: showfileupload["len_file"],
        color: this.color.theme,
        file_duplicate: showfileupload["file_duplicate"],
        size: showfileupload["size"],
        copy_paste: false,
        allchunk: [],
        numchunk: 0,
        parts: [],
        listxhr: [],
        checkcancelone: false
      };
      // เข้าเมื่อโฟลเดอร์นี้อยู่ในโฟลเดอร์ที่ซ้ำ
      if (
        datafileduplicate.type === "folder" &&
        this.listpathfolderduplicate.includes(showfileupload.path) === true
      ) {
        let pathfolder = datafileduplicate.path + datafileduplicate.name + "/";
        let mainfolder = datafileduplicate.path.split("/");
        datafileduplicate.mainfolder = mainfolder[1];
        this.listpathfolderduplicate.push(pathfolder);
        this.listfileinfolderduplicate.push(datafileduplicate);
      }
      // เข้าเมื่อเป็นโฟลเดอร์
      else if (datafileduplicate.type === "folder") {
        let pathfolder = datafileduplicate.path + datafileduplicate.name + "/";
        this.listpathfolderduplicate.push(pathfolder);
        this.listfileduplicate.push(datafileduplicate);
      }
      // เข้าเมื่อไฟล์นี้อยู่ในโฟลเดอร์ที่ซ้ำ
      else if (
        datafileduplicate.type !== "folder" &&
        this.listpathfolderduplicate.includes(showfileupload.path) === true
      ) {
        let mainfolder = datafileduplicate.path.split("/");
        datafileduplicate.mainfolder = mainfolder[1];
        this.listfileinfolderduplicate.push(datafileduplicate);
      }
      // เข้าเมื่อเป็นไฟล์
      else {
        this.listfileduplicate.push(datafileduplicate);
      }
    },
    // ฟังก์ชันเช็คไฟล์ซ้ำ
    async checkDuplicateData(i) {
      // หาที่ folder_id ที่เป็นที่อยู่ของไฟล์/โฟลเดอร์
      if (this.showfileupload[i]["path"] === "/") {
        this.parentfolderDD = this.sendParentfolder();
      } else {
        // เช็ค path เพื่อตรวจสอบ parent folder
        for (let j = 0; j < this.showfileupload.length; j++) {
          if (this.showfileupload[j]["type"] === "folder") {
            if (
              this.showfileupload[j]["path"] +
                this.showfileupload[j]["name"] +
                "/" +
                this.showfileupload[i]["name"] ===
              this.showfileupload[i]["data"].fullPath
            ) {
              this.parentfolderDD = this.showfileupload[j]["folderid"];
            }
          }
        }
      }
      const CancelToken = this.axios.CancelToken;
      this.source = CancelToken.source();
      this.showfileupload[i].canceltoken = this.source;
      let payload = {
        folder_id: this.parentfolderDD,
        data_type:
          this.showfileupload[i]["type"] === "folder" ? "folder" : "file",
        data_name: this.showfileupload[i]["name"],
      };
      let auth = await gbfGenerate.generateToken();
      try {
        let response = await this.axios({
          method: "POST",
          url:
            process.env.VUE_APP_SERVICE_UPLOAD_API +
            "/api/v1/check_duplicate_data",
          // cancelToken: this.source.token,
          cancelToken: this.showfileupload[i]["canceltoken"].token,
          data: payload,
          headers: { Authorization: auth.code },
        });
        if (response.data.status === "OK") {
          if (
            response.data.message === "duplicate foldername" ||
            response.data.message === "duplicate filename"
          ) {
            if (this.showfileupload[i]["type"] === "folder") {
              this.showfileupload[i]["folderid"] = response.data.data.folder_id;
            } else {
              this.showfileupload[i]["folderid"] = this.parentfolderDD;
            }
            this.showfileupload[i]["value"] = 100;
            this.showfileupload[i]["status"] = {
              code: "duplicate",
              message: this.showfileupload[i]["type"] === "folder" ? "dragdrop.duplicatefolder" : "dragdrop.duplicatefile",
              devMessage: ""
            };
            this.showfileupload[i]["color"] = "warning";
            this.percentfileuploading = this.showfileupload[i]["value"];
            return new Promise(async (resolve) => {
              resolve({
                status: "duplicate",
                msg: response.data.message,
              });
            });
          } else {
            return new Promise(async (resolve) => {
              resolve({
                status: "unique",
                msg: response.data.message,
              });
            });
          }
        } else {
          // this.count_uploadfail++;
          this.status_uploadfail = true;
          this.openclose = true;
          this.showfileupload[i]["color"] = "red";
          this.showfileupload[i]["value"] = 100;
          this.showfileupload[i]["status"] = {
            code: "fail",
            message: "dragdrop.duplicatefail",
            devMessage: response.data.errorMessage
          };
          this.percentfileuploading = this.showfileupload[i]["value"];
          return new Promise(async (resolve) => {
            resolve({
              status: "upload denied",
              msg: "dragdrop.duplicatefail",
            });
          });
        }
      } catch (err) {
        if (this.count_cancel === 1 || this.showfileupload[i].checkcancelone === true) {
          // this.count_uploadfail++;
          this.status_uploadfail = true;
          this.openclose = true;
          this.showfileupload[i]["color"] = "red";
          this.showfileupload[i]["value"] = 100;
          this.showfileupload[i]["status"] = {
            code: "cancel",
            message: "dragdrop.cancelupload",
            devMessage: ""
          };
          this.percentfileuploading = this.showfileupload[i]["value"];
          return new Promise(async (resolve) => {
            resolve({
              status: "upload denied",
              msg: "dragdrop.cancelupload",
            });
          });
        } else {
          // this.count_uploadfail++;
          this.status_uploadfail = true;
          this.openclose = true;
          this.showfileupload[i]["color"] = "red";
          this.showfileupload[i]["value"] = 100;
          this.showfileupload[i]["status"] = {
            code: "fail",
            message: "dragdrop.duplicatefail",
            devMessage: err
          };
          this.percentfileuploading = this.showfileupload[i]["value"];
          return new Promise(async (resolve) => {
            resolve({
              status: "upload denied",
              msg: "dragdrop.duplicatefail",
            });
          });
        }
      }
    },
    async checkUploading(i) {
      let responsecheckduplicate = {};
      if (this.showfileupload[i]) {
        this.showfileupload[i]
          ? (this.showfileupload[i].status = {
              code: "Uploading",
              message: "dragdrop.uploading",
              devMessage: ""
            })
          : "";
        if (this.count_cancel === 1) {
          this.status_uploadfail = true;
          this.openclose = true;
          this.showfileupload[i]["value"] = 100;
          this.showfileupload[i]["status"] = {
            code: "cancel",
            message: "dragdrop.cancelupload",
            devMessage: "",
          };
        } else if (
          this.showfileupload[i]["path"] !== "/" &&
          this.listpathfolderduplicate.includes(
            this.showfileupload[i]["path"]
          ) === true
        ) {
          if (this.showfileupload[i]["type"] === "folder") {
            this.checkupfolder = false;
          }
          this.addFolderDuplicate(this.showfileupload[i]);
          this.showfileupload[i]["value"] = 100;
          this.showfileupload[i]["status"] = {
            code: "duplicate",
            message: this.showfileupload[i]["type"] === "folder" ? "dragdrop.duplicatefolder" : "dragdrop.duplicatefile",
            devMessage : "",
          };
          this.showfileupload[i]["color"] = "warning";
          this.percentfileuploading = this.showfileupload[i]["value"];
          // responsecheckduplicate.status = "duplicate";
        } else {
          let check_duplicate_newdragdrop = this.showfileupload.filter(item => (item.name === this.showfileupload[i].name && item.status.code !== "cancel" && item.status.code !== "fail" && item.copy_paste === false && item.fullPath === this.showfileupload[i].fullPath));
          if (check_duplicate_newdragdrop.length > 1) {
            if (this.showfileupload[i]["type"] === "folder") {
              this.checkupfolder = false;
            }
            responsecheckduplicate.status = "duplicate"
            this.showfileupload[i]["value"] = 100;
            this.showfileupload[i]["status"] = {
              code: "duplicate",
              message: this.showfileupload[i]["type"] === "folder" ? "dragdrop.duplicatefolder" : "dragdrop.duplicatefile",
              devMessage: ""
            };
            this.showfileupload[i]["color"] = "warning";
          } else {
            if (this.showfileupload[i]["path"] === "/") {
              responsecheckduplicate =
                this.checkfirstloopchoice === true
                  ? ""
                  : this.showfileupload[i].copy_paste === true ? "" : await this.checkDuplicateData(i);
            }
            if (responsecheckduplicate.status === "duplicate") {
              if (this.showfileupload[i]["type"] === "folder") {
                this.checkupfolder = false;
              }
              this.showfileupload[i]["value"] = 100;
              this.showfileupload[i]["status"] = {
                code: "duplicate",
                message: this.showfileupload[i]["type"] === "folder" ? "dragdrop.duplicatefolder" : "dragdrop.duplicatefile",
                devMessage: ""
              };
              this.addFolderDuplicate(this.showfileupload[i]);
            } else if (responsecheckduplicate.status === "upload denied") {
              if (this.count_cancel === 1) {
                this.status_uploadfail = true;
                this.openclose = true;
                this.showfileupload[i]["value"] = 100;
                this.showfileupload[i]["status"] = {
                  code: "cancel",
                  message: "dragdrop.cancelupload",
                  devMessage: "",
                };
              } else {
                this.status_uploadfail = true;
                this.openclose = true;
                this.showfileupload[i]["value"] = 100;
                this.showfileupload[i]["status"] = {
                  code: "fail",
                  message: "dragdrop.cannotdetailupload",
                  devMessage: "",
                };
              }
              if (this.showfileupload[i]["type"] === "folder") {
                this.checkupfolder = false;
              }
            } else {
              // ต้องไม่มี error และต้องไม่ cencel
              if (
                this.showfileupload.length !== i &&
                // this.showfileupload[i].errorduplicate === "not" &&
                this.showfileupload[i].status.code !== "cancel"
              ) {
                if (this.showfileupload[i]["type"] == "folder") {
                  await this.fn_uploadfolder(this.showfileupload[i]);
                } else {
                  this.fnCheckingFileData(
                    this.showfileupload[i],
                    i,
                    this.showfileupload[i]["numchunk"]
                  );
                }
              } 
              // else {
              //   this.showfileupload[i]
              //     ? (this.showfileupload[i].status = {
              //       code: "duplicate",
              //       message: "dragdrop.cannotdetailupload",
              //       devMessage: err,
              //     })
              //     : "";
              // }
            }
          }
        }
      }
    },
    // ฟังก์ชันวนลูปอัปโหลดไฟล์/โฟลเดอร์
    async fn_loop_uploadfolder() {
      console.log("uploadchunkja this.showfileupload", this.showfileupload);
      this.entryTotal = this.showfileupload.length;
      // วนก้อนข้อมูลไฟล์โฟลเดอร์ทั้งหมดที่อ่านได้
      // this.source = CancelToken.source();
      this.checkcurrentupload = true;
      this.stacksizefile = 0;
      let responsecheckduplicate;
      let count_upload_ = 0;
      var i = this.count_upload,
        len = this.showfileupload.length;
      while (i < len) {
        try {
          let check_duplicate_newdragdrop = this.showfileupload.filter(item => (item.name === this.showfileupload[i].name && item.status.code !== "cancel" && item.status.code !== "fail" && item.copy_paste === false));
          console.log("check_duplicate_newdragdrop", check_duplicate_newdragdrop);
          this.showfileupload[i]["canceltoken"] = CancelToken.source();
          // มีการกด cancel
          if (this.count_cancel === 1) {
            break;
          } else if (
            this.showfileupload[i]["path"] !== "/" &&
            this.listpathfolderduplicate.includes(
              this.showfileupload[i]["path"]
            ) === true
          ) {
            // this.count_upload++;
            this.showfileupload[i]["value"] = 0;
            this.showfileupload[i]["status"] = {
              code: "duplicate",
              message: this.showfileupload[i]["type"] === "folder" ? "dragdrop.duplicatefolder" : "dragdrop.duplicatefile",
              devMessage : "",
            };
            this.showfileupload[i]["color"] = "warning";
            this.percentfileuploading = this.showfileupload[i]["value"];
            this.addFolderDuplicate(this.showfileupload[i]);
            // responsecheckduplicate.status = "duplicate";
          } else {
            if (this.showfileupload[i]["type"] !== "folder") {
              if (this.$route.name !== "directorysharefile") {
                await this.$store.dispatch("check_storage", this.dataAccountId);
                this.remain_storage = await gbfsizeDataConverter.convertStorageToByte(this.dataRemainStorage);
              }
            }
            // if(this.showfileupload[i]["size"]){

            // }
            if (
              this.remain_storage < this.showfileupload[i]["size"] &&
              this.showfileupload[i]["type"] !== "folder"  &&
              this.$route.name !== "directorysharefile"
            ) {
              // this.count_upload++;
              // this.count_uploadfail++;
              this.status_uploadfail = true;
              this.openclose = true;
              this.showfileupload[i]["value"] = 100;
              this.showfileupload[i]["status"] = {
                code: "fail",
                message: "dragdrop.yourspacenotenough",
                devMessage : "",
              };
              this.showfileupload[i]["color"] = "red";
              this.percentfileuploading = this.showfileupload[i]["value"];
            } else if (
              this.remain_storage < 0 ||
              (this.remain_storage <
                this.stacksizefile + this.showfileupload[i]["size"] &&
                this.showfileupload[i]["type"] !== "folder"
              ) &&
              this.$route.name !== "directorysharefile"
            ) {
              // this.count_upload++;
              // this.count_uploadfail++;
              this.status_uploadfail = true;
              this.openclose = true;
              this.showfileupload[i]["value"] = 100;
              this.showfileupload[i]["status"] = {
                code: "fail",
                message: "dragdrop.yourspacenotenough",
                devMessage : "",
              };
              this.showfileupload[i]["color"] = "red";
              this.percentfileuploading = this.showfileupload[i]["value"];
            } else {
              // ไฟล์/โฟลเดอร์ใน root
              console.log("เข้า1");
              this.percentuploadfolder += 100 / this.showfileupload.length;
              // this.count_upload++;
              this.datauploading_name = this.showfileupload[i]["name"];
              this.percentfileuploading = this.showfileupload[i]["value"];
              this.foldername_upload = this.showfileupload[i]["name"];
              if (this.showfileupload[i]["path"] === "/") {
                responsecheckduplicate =
                  this.checkfirstloopchoice === true
                    ? ""
                    : this.showfileupload[i].copy_paste === true ? "" : await this.checkDuplicateData(i);
              }
              if (check_duplicate_newdragdrop.length > 1) {
                responsecheckduplicate.status = "duplicate"
                this.showfileupload[i]["value"] = 100;
                this.showfileupload[i]["status"] = {
                  code: "duplicate",
                  message: this.showfileupload[i]["type"] === "folder" ? "dragdrop.duplicatefolder" : "dragdrop.duplicatefile",
                  devMessage: ""
                };
                this.showfileupload[i]["color"] = "warning";
              }
              if (responsecheckduplicate.status === "duplicate") {
                console.log("uploadmaja ไม่ไปป");
                this.addFolderDuplicate(this.showfileupload[i]);
                count_upload_ = 0;
              } else if (responsecheckduplicate.status === "upload denied") {
                if (this.count_cancel === 1) {
                  break;
                } else {
                  continue;
                }
              } else {
                if(this.showfileupload[i]["copy_paste"] === true){
                  this.stacksizefile += this.showfileupload[i]["size"]
                  console.log("Count upload ", this.showfileupload[i])
                  this.fn_copy_and_paste_files(this.showfileupload[i])
                } else {
                if (this.showfileupload[i].type === "folder") {
                  await this.fn_uploadfolder(this.showfileupload[i]);
                } else if (count_upload_ < 5) {
                  this.fn_uploadfolder(this.showfileupload[i]);
                  count_upload_++;
                } else {
                  await this.fn_uploadfolder(this.showfileupload[i]);
                  count_upload_ = 0;
                }
                }
              }
            }
          }
          i++;
          if (this.showfileupload.length === i) {
            if (this.$route.name !== "directorysharefile") {
              await this.$store.dispatch("check_storage", this.dataAccountId);
              this.remain_storage = await gbfsizeDataConverter.convertStorageToByte(
                this.dataRemainStorage
              );
              console.log("Check Size remain ", this.remain_storage)
            }
          }
        } catch (err) {
          i++;
          console.log(err);
        }
      }
      // เปิด dialog สำหรับไฟล์/โฟลเดอร์ซ้ำ
      if (this.listfileduplicate.length > 0) {
        this.openalertduplicatefile = true;
      }
      this.checkcurrentupload = false;
    },
    // ฟังก์ชันอัปโหลดไฟล์(เช็คว่าเป็น chunk file มั้ย)/โฟลเดอร์
    async fn_uploadfolder(item) {
      this.overmaxsize = false;
      this.opencancel = false;
      let objIndex = this.showfileupload.findIndex((obj) => obj.id === item.id);
      try {
        // เป็นไฟล์
        if (item.data.isFile) {
          // this.stacksizefile =
          //   this.stacksizefile + this.showfileupload[objIndex]["size"];
          // //  16106127360 = 15GB / 5368709120 = 5GB
          // let maxSize =
          //   this.dataCheckPackage.type === "Business"
          //     ? this.dataCheckPackage.package
          //       ? 5368709120
          //       : 524288000
          //     : this.dataCheckPackage.package
          //     ? 5368709120
          //     : 524288000;
          // if (this.showfileupload[objIndex]["size"] >= maxSize) {
          //   // this.count_uploadfail++;
          //   this.showfileupload[objIndex]["value"] = 100;
          //   this.showfileupload[objIndex]["status"] = {
          //     code: "fail",
          //     message: "dragdrop.morefivegb",
          //     devMessage : "",
          //   };
          //   this.showfileupload[objIndex]["color"] = "red";
          //   this.percentfileuploading = this.showfileupload[objIndex]["value"];
          //   this.status_uploadfail = true;
          //   this.openclose = true;
          // } else {
            // if (this.showfileupload[objIndex]["size"] > 104857600) {
            // if (this.showfileupload[objIndex]["size"] > 524288000) {
            //   await this.fn_upload_chunk(item);
            // } else {
            //   await this.fn_uploadfile_nochunk(item);
            // }
          // }
          // await this.fnCheckingFileData(item)
        }
        // เป็นโฟลเดอร์
        else if (item.data.isDirectory) {
          try {
            let payload = await this.fn_check_payload_folder(item);
            let url;
            if (this.$route.name === "directorysharefile") {
              url =
                process.env.VUE_APP_SERVICE_UPLOAD_FOLDER +
                "/api/insert_folder_share";
            } else {
              url =
                process.env.VUE_APP_SERVICE_UPLOAD_FOLDER +
                "/api/v2/insert_folder";
            }
            let auth = await gbfGenerate.generateToken();
            let result = await this.axios({
              method: "POST",
              url: url,
              // cancelToken: this.source.token,
              // cancelToken: this.showfileupload[objIndex]["canceltoken"].token,
              data: payload.msg,
              headers: { Authorization: auth.code },
              onUploadProgress: (e) => {
                if ((e.loaded / e.total) * 100 >= 95) {
                  this.showfileupload[objIndex]["value"] = 95;
                  this.percentfileuploading = this.showfileupload[objIndex][
                    "value"
                  ];
                } else {
                  this.showfileupload[objIndex]["value"] = parseInt(
                    (e.loaded / e.total) * 100
                  );
                  this.percentfileuploading = this.showfileupload[objIndex][
                    "value"
                  ];
                }
              },
            });
            if (result.data.status === "OK") {
              this.showfileupload[objIndex]["folderid"] =
                result.data.data.folder_id;
              this.showfileupload[objIndex]["value"] = 100;
              this.showfileupload[objIndex]["status"] = {
                code: "success",
                message: "dragdrop.uploadsuccess",
                devMessage : "",
              };
              this.showfileupload[objIndex]["color"] = "green";
              this.percentfileuploading = this.showfileupload[objIndex][
                "value"
              ];
              this.checkupfolder = false;
              // if (
              //   this.percentuploadedallfile === 100 &&
              //   this.listfileduplicate.length === 0 &&
              //   this.openclose === false
              // ) {
              //   this.loopsuccess = true;
              //   this.fn_close_snackbar();
              // }
            } else {
              // this.count_uploadfail++;
              this.status_uploadfail = true;
              this.openclose = true;
              this.showfileupload[objIndex]["value"] = 100;
              this.showfileupload[objIndex]["status"] = {
                code: "fail",
                message: "dragdrop.cannotdetailupload",
                devMessage : result.data.errorMessage,
              };
              this.showfileupload[objIndex]["color"] = "red";
              this.percentfileuploading = this.showfileupload[objIndex][
                "value"
              ];
              this.checkupfolder = false;
            }
          } catch (err) {
            console.log(err);
            if (this.count_cancel === 1 || this.showfileupload[objIndex].checkcancelone === true) {
              // this.count_uploadfail++;
              this.status_uploadfail = true;
              this.openclose = true;
              this.showfileupload[objIndex]["value"] = 100;
              this.showfileupload[objIndex]["status"] = {
                code: "cancel",
                message: "dragdrop.cancelupload",
                devMessage: "",
              };
              this.showfileupload[objIndex]["color"] = "red";
              this.percentfileuploading = this.showfileupload[objIndex][
                "value"
              ];
            } else {
              // this.count_uploadfail++;
              this.status_uploadfail = true;
              this.openclose = true;
              this.showfileupload[objIndex]["value"] = 100;
              this.showfileupload[objIndex]["status"] = {
                code: "fail",
                message: "dragdrop.cannotdetailupload",
                devMessage: err,
              };
              this.showfileupload[objIndex]["color"] = "red";
              this.percentfileuploading = this.showfileupload[objIndex][
                "value"
              ];
            }
            this.checkupfolder = false;
          }
        }
      } catch (ex) {
        console.log(ex);
        if (this.count_cancel === 1 || this.showfileupload[objIndex].checkcancelone === true) {
          // this.count_uploadfail++;
          this.status_uploadfail = true;
          this.openclose = true;
          this.showfileupload[objIndex]["value"] = 100;
          this.showfileupload[objIndex]["status"] = {
            code: "cancel",
            message: "dragdrop.cancelupload",
            devMessage: "",
          }
          this.showfileupload[objIndex]["color"] = "red";
          this.percentfileuploading = this.showfileupload[objIndex]["value"];
        } else {
          // this.count_uploadfail++;
          this.status_uploadfail = true;
          this.openclose = true;
          this.showfileupload[objIndex]["value"] = 100;
          this.showfileupload[objIndex]["status"] = {
            code: "fail",
            message: "dragdrop.cannotdetailupload",
            devMessage: ex,
          };
          this.showfileupload[objIndex]["color"] = "red";
          this.percentfileuploading = this.showfileupload[objIndex]["value"];
        }
        this.checkupfolder = false;
      }
    },
    // ฟังก์ชันวนลูป อัปโหลดไฟล์ที่เป็น chunk
    async fn_upload_chunk(item) {
      this.checkduplicatechunk = false;
      let objIndex = this.showfileupload.findIndex((obj) => obj.id === item.id);
      let payload;
      let payload_merge;
      try {
        await this.create_chunk(item);
        await this.generate_uuid(item);
        for (let i = 0; i < this.chunks.length; i++) {
          if (this.remain_storage > this.showfileupload[objIndex]["size"] || this.$route.name === "directorysharefile") {
            this.loop = i;
            let res_ = await this.fn_check_payload_file(item);
            payload = res_.msg;
            let res = await this.fn_uploadfile_chunk(payload, item, i);
            if (res.status === "upload denied") {
              break;
            }
            if (i === this.chunks.length - 1) {
              let res_p = await this.fn_check_payload_file_merge(item);
              payload_merge = res_p.msg;
              // this.checkduplicatechunk === true
              //   ? ""
              //   : payload_merge.set("file_duplicate", "");
              await this.fn_uploadfile_merge(payload_merge, item);
              if (this.$route.name !== "directorysharefile") {
                await this.$store.dispatch("check_storage", this.dataAccountId);
                this.remain_storage = await gbfsizeDataConverter.convertStorageToByte(
                  this.dataRemainStorage
                );
              }
            }
            console.log("chunkjaaa remain", this.remain_storage);
            console.log("chunkjaaa stak", this.stacksizefile);
          } else {
            // this.count_uploadfail++;
            this.status_uploadfail = true;
            this.openclose = true;
            this.showfileupload[objIndex]["value"] = 100;
            this.showfileupload[objIndex]["status"] = {
              code: "fail",
              message: "dragdrop.yourspacenotenough",
              devMessage: "",
            };
            this.showfileupload[objIndex]["color"] = "red";
            this.percentfileuploading = this.showfileupload[objIndex]["value"];
            this.stacksizefile =
              this.stacksizefile - this.showfileupload[objIndex]["size"];
            break;
          }
        }
      } catch (err) {
        if (this.count_cancel === 1 || this.showfileupload[objIndex].checkcancelone === true) {
          // this.count_uploadfail++;
          this.status_uploadfail = true;
          this.openclose = true;
          this.showfileupload[objIndex]["value"] = 100;
          this.showfileupload[objIndex]["status"] = {
            code: "cancel",
            message: "dragdrop.cancelupload",
            devMessage: "",
          };
          this.showfileupload[objIndex]["color"] = "red";
          this.percentfileuploading = this.showfileupload[objIndex]["value"];
        } else {
          // this.count_uploadfail++;
          this.status_uploadfail = true;
          this.openclose = true;
          this.showfileupload[objIndex]["value"] = 100;
          this.showfileupload[objIndex]["status"] = {
            code: "fail",
            message: "dragdrop.cannotdetailupload",
            devMessage: err,
          };
          this.showfileupload[objIndex]["color"] = "red";
          this.percentfileuploading = this.showfileupload[objIndex]["value"];
          this.stacksizefile =
            this.stacksizefile - this.showfileupload[objIndex]["size"];
        }
      }
    },
    // ฟังก์ชันอัปโหลดไฟล์ที่เป็น chunk
    async fn_uploadfile_chunk(payload, item, i) {
      let objIndex = this.showfileupload.findIndex((obj) => obj.id === item.id);
      let len_file = Math.ceil(this.chunks.length / this.part_per_send);
      if (this.$route.name === "directorysharefile") {
        payload.append("folder_from_share", "Y");
      }
      let auth = await gbfGenerate.generateToken();
      try {
        let result = await this.axios({
          method: "POST",
          url:
            process.env.VUE_APP_SERVICE_UPLOAD_API + "/api/upload_files_chunk",
          // cancelToken: this.source.token,
          cancelToken: this.showfileupload[objIndex]["canceltoken"].token,
          data: payload,
          headers: { Authorization: auth.code },
        });
        if (result.data.status === "OK") {
          this.showfileupload[objIndex]["value"] = Math.round(
            (i / len_file) * 100
          );
          this.showfileupload[objIndex]["status"] = {
            code: "start",
            message: "dragdrop.uploading",
            devMessage: "",
          };
          this.showfileupload[objIndex]["folderid"] = this.parentfolderDD;
          this.percentfileuploading = this.showfileupload[objIndex]["value"];
          return new Promise(async (resolve) => {
            resolve({
              status: "upload success",
              msg: result.data.message,
            });
          });
        } else {
          this.showfileupload[objIndex]["status"] = {
            code: "fail",
            message: "dragdrop.cannotdetailupload",
            devMessage: result.data.errorMessage,
          };
          this.showfileupload[objIndex]["color"] = "red";
          this.showfileupload[objIndex]["value"] = 100;
          this.percentfileuploading = this.showfileupload[objIndex]["value"];
          // this.count_uploadfail++;
          this.status_uploadfail = true;
          this.openclose = true;
          this.stacksizefile =
            this.stacksizefile - this.showfileupload[objIndex]["size"];
          return new Promise(async (resolve) => {
            resolve({
              status: "upload denied",
              msg: result.data.errorMessage,
            });
          });
        }
      } catch (err) {
        console.log("err uploadchunkja", err);
        if (this.count_cancel === 1 || this.showfileupload[objIndex].checkcancelone === true) {
          this.showfileupload[objIndex]["color"] = "red";
          this.showfileupload[objIndex]["value"] = 100;
          this.showfileupload[objIndex]["status"] = {
            code: "cancel",
            message: "dragdrop.cancelupload",
            devMessage: "",
          };
          return new Promise(async (resolve) => {
            resolve({
              status: "upload denied",
              msg: this.$t("dragdrop.cancelupload"),
            });
          });
        } else {
          this.stacksizefile =
            this.stacksizefile - this.showfileupload[objIndex]["size"];
          this.showfileupload[objIndex]["color"] = "red";
          this.showfileupload[objIndex]["value"] = 100;
          this.showfileupload[objIndex]["status"] = {
            code: "fail",
            message: "dragdrop.cannotdetailupload",
            devMessage: err,
          };
          return new Promise(async (resolve) => {
            resolve({
              status: "upload denied",
              msg: err,
            });
          });
        }
      }
    },
    // ฟังก์ชัน merge อัปโหลดไฟล์ chunk
    async fn_uploadfile_merge(payload_merge, item) {
      this.count_merge++;
      let objIndex = this.showfileupload.findIndex((obj) => obj.id === item.id);
      if (this.$route.name === "directorysharefile") {
        payload_merge.append("folder_from_share", "Y");
      }
      let auth = await gbfGenerate.generateToken();
      try {
        let result = await this.axios({
          method: "POST",
          url:
            process.env.VUE_APP_SERVICE_UPLOAD_API + "/api/merge_files_chunk",
          // cancelToken: this.source.token,
          cancelToken: this.showfileupload[objIndex]["canceltoken"].token,
          data: payload_merge,
          headers: { Authorization: auth.code },
          onUploadProgress: (e) => {
            // เมื่อกดยกเลิกจะตกไป catch
            if ((e.loaded / e.total) * 100 >= 95) {
              this.showfileupload[objIndex]["value"] = 99;
              this.percentfileuploading = this.showfileupload[objIndex][
                "value"
              ];
              this.percentfileuploading = this.showfileupload[objIndex][
                "value"
              ];
            } else {
              this.showfileupload[objIndex]["value"] = parseInt(
                (e.loaded / e.total) * 100
              );
              this.percentfileuploading = this.showfileupload[objIndex][
                "value"
              ];
              this.percentfileuploading = this.showfileupload[objIndex][
                "value"
              ];
            }
          },
        });
        this.loop = 0;
        if (result.data.status === "OK") {
          this.count_merge = this.count_merge - 1;
          this.stacksizefile =
            this.stacksizefile - this.showfileupload[objIndex].size;
          this.showfileupload[objIndex]["value"] = 100;
          this.showfileupload[objIndex]["status"] = {
            code: "success",
            message: "dragdrop.uploadsuccess",
            devMessage: "",
          };
          this.showfileupload[objIndex]["color"] = "green";
          this.showfileupload[objIndex]["folderid"] = this.parentfolderDD;
          this.percentfileuploading = this.showfileupload[objIndex]["value"];
          // if (
          //   this.percentuploadedallfile === 100 &&
          //   this.listfileduplicate.length === 0 &&
          //   this.openclose === false
          // ) {
          //   this.loopsuccess = true;
          //   this.fn_close_snackbar();
          // }
        } else {
          this.count_merge = this.count_merge - 1;
          this.stacksizefile =
            this.stacksizefile - this.showfileupload[objIndex]["size"];
          this.showfileupload[objIndex]["status"] =
            result.data.errorMessage === "Your space not enough"
              ? {
                  code: "fail",
                  message: "dragdrop.yourspacenotenough",
                  devMessage: "",
                }
              : {
                  code: "fail",
                  message: "dragdrop.cannotdetailupload",
                  devMessage: result.data.errorMessage,
                }
              ;
          this.showfileupload[objIndex]["color"] = "red";
          this.showfileupload[objIndex]["value"] = 100;
          this.percentfileuploading = this.showfileupload[objIndex]["value"];
        }
      } catch (err) {
        if (this.count_cancel === 1 || this.showfileupload[objIndex].checkcancelone === true) {
          this.count_merge = this.count_merge - 1;
          this.showfileupload[objIndex]["status"] = {
            code: "cancel",
            message: "dragdrop.cancelupload",
            devMessage: result.data.errorMessage,
          };
          this.showfileupload[objIndex]["color"] = "red";
          this.showfileupload[objIndex]["value"] = 100;
          this.percentfileuploading = this.showfileupload[objIndex]["value"];
        } else {
          this.count_merge = this.count_merge - 1;
          this.stacksizefile =
            this.stacksizefile - this.showfileupload[objIndex]["size"];
          this.showfileupload[objIndex]["status"] = {
            code: "cancel",
            message: "dragdrop.cannotdetailupload",
            devMessage: err,
          };
          this.showfileupload[objIndex]["color"] = "red";
          this.showfileupload[objIndex]["value"] = 100;
          this.percentfileuploading = this.showfileupload[objIndex]["value"];
        }
      }
    },
    callfolderid(item) {
      if (item.path === "/") {
        return this.sendParentfolder();
      } else {
        for (let i = 0; i < this.showfileupload.length; i++) {
          if (this.showfileupload[i]["type"] === "folder") {
            if (
              item["path"].substring(0, item["path"].length - 1) ===
              this.showfileupload[i]["data"].fullPath
            ) {
              return this.showfileupload[i]["folderid"];
            }
          }
        }
      }
    },
    async fnCheckingFileData(item, numchunk) {
      try {
        const CancelToken = this.axios.CancelToken;
        this.source = CancelToken.source();
        item.canceltoken = this.source;
        let files = await this.getFile(item.data);
        let folderid = await this.callfolderid(item)
        let objIndex = this.showfileupload.findIndex((obj) => obj.id === item.id);
        let payload_nochunk = {
          user_id: this.dataUsername,
          account_id: this.dataAccountId,
          folder_id: folderid,
          file_duplicate: this.showfileupload[objIndex].file_duplicate,
          filename: files.name,
          remark: "",
          size_file: files.size,
          department_id: this.departmentid,
          hashtag: []
        }
        if (this.$route.name === "directorysharefile") {
          payload_nochunk.folder_from_share = "Y";
        }
        let auth = await gbfGenerate.generateToken();
        let result = await this.axios({
          method: "POST",
          url: process.env.VUE_APP_SERVICE_UPLOAD_API + "/api/v1/checking_file_data/upload",
          cancelToken: this.showfileupload[objIndex]["canceltoken"].token,
          data: payload_nochunk,
          headers: { Authorization: auth.code },
          // onUploadProgress: (e) => {
          //   this.showfileupload[objIndex]["status"] = {
          //     code: "start",
          //     message: "dragdrop.uploading",
          //     devMessage: "",
          //   };
          //   // เมื่อกดยกเลิกจะไปตกที่ catch
          //   if ((e.loaded / e.total) * 100 >= 95) {
          //     this.showfileupload[objIndex]["value"] = 95;
          //     this.percentfileuploading = this.showfileupload[objIndex][
          //       "value"
          //     ];
          //   } else {
          //     this.showfileupload[objIndex]["value"] = parseInt(
          //       (e.loaded / e.total) * 100
          //     );
          //     this.percentfileuploading = this.showfileupload[objIndex][
          //       "value"
          //     ];
          //   }
          // },
        });
        if (result.data.status === "OK") {
           if (Object.keys(result.data.data.uploadChunk).length > 0) {
            this.datachunkwaiting = item;
            console.log("item.allchunk", item);
            item["datafile"] = result.data.data;
            this.fnuploadChunks3(result.data.data, objIndex, 0, "");
          } else {
            this.fnUploads3(result.data.data, item);
          }
          // this.stacksizefile =
          //   this.stacksizefile - this.showfileupload[objIndex]["size"];
          // this.showfileupload[objIndex]["value"] = 100;
          // this.showfileupload[objIndex]["status"] = {
          //   code: "success",
          //   message: "dragdrop.uploadsuccess",
          //   devMessage: "",
          // };
          // this.showfileupload[objIndex]["color"] = "green";
          // this.showfileupload[objIndex]["folderid"] = this.parentfolderDD;
          // this.percentfileuploading = this.showfileupload[objIndex]["value"];
          // if (
          //   this.percentuploadedallfile === 100 &&
          //   this.listfileduplicate.length === 0 &&
          //   this.openclose === false
          // ) {
          //   this.loopsuccess = true;
          //   this.fn_close_snackbar();
          // }
        } else {
          // this.count_uploadfail++;
          this.openclose = true;
          this.status_uploadfail = true;
          this.stacksizefile =
            this.stacksizefile - this.showfileupload[objIndex]["size"];
          this.showfileupload[objIndex]["value"] = 100;
          this.showfileupload[objIndex]["status"] =
            result.data.errorMessage === "Your space not enough"
              ? {
                  code: "fail",
                  message: "dragdrop.yourspacenotenough",
                  devMessage: "",
                }
              : {
                  code: "fail",
                  message: "dragdrop.cannotdetailupload",
                  devMessage: result.data.errorMessage,
                };
          this.showfileupload[objIndex]["color"] = "red";
          this.percentfileuploading = this.showfileupload[objIndex]["value"];
        }
      } catch (err) {
        console.log("err", err);
        if (this.count_cancel === 1 || this.showfileupload[objIndex].checkcancelone === true) {
          // this.count_uploadfail++;
          this.status_uploadfail = true;
          this.openclose = true;
          this.showfileupload[objIndex]["value"] = 100;
          this.showfileupload[objIndex]["status"] = {
            code: "cancel",
            message: "dragdrop.cancelupload",
            devMessage: err,
          };
          this.showfileupload[objIndex]["color"] = "red";
          this.percentfileuploading = this.showfileupload[objIndex]["value"];
        } else {
          // this.count_uploadfail++;
          this.status_uploadfail = true;
          this.openclose = true;
          this.stacksizefile =
            this.stacksizefile - this.showfileupload[objIndex]["size"];
          this.showfileupload[objIndex]["value"] = 100;
          this.showfileupload[objIndex]["status"] = {
            code: "fail",
            message: "dragdrop.cannotdetailupload",
            devMessage: err,
          };
          this.showfileupload[objIndex]["color"] = "red";
          this.percentfileuploading = this.showfileupload[objIndex]["value"];
        }
      }
    },
    async fnuploadChunks3(datafile, i, numchunk, checkreload) {
      try {
        if (numchunk === 0 && checkreload === "") {
          await this.create_chunk_new(this.showfileupload[i]);
          await this.generate_uuid(i);
          this.showfileupload[i]["parts"] = [];
          this.checkopenchunk = true;
          console.log("this.showfileupload[i]allchunk", this.showfileupload[i]["allchunk"]);
          this.chunkwaiting = this.chunkwaiting.concat(this.showfileupload[i]["allchunk"])
          console.log("weting",this.chunkwaiting);
        }
        if (this.showfileupload[i]["allchunk"].length > 0) {
          if (this.showfileupload[i]["status"].code === "Uploading") {
            try {
              let url = await this.fnGetPresignChunk(
                datafile,
                i,
                this.showfileupload[i]["allchunk"][numchunk]["PartNumber"]
              );
              if (url !== "fail") {
                this.showfileupload[i]["allchunk"][numchunk]["status"] ===
                  "Uploading";
                console.log("all",this.showfileupload[i]["allchunk"]);
                console.log("weting",this.chunkwaiting);
                let onechunkwaiting = this.chunkwaiting.filter(item => item.id === this.showfileupload[i].id);
                console.log("onechunkwaiting", onechunkwaiting);
                let indexwatingchunk = this.chunkwaiting.indexOf(onechunkwaiting[numchunk])
                console.log("nechunkwaiting[numchunk]", onechunkwaiting[numchunk]);
                console.log("onechunkwaiting", indexwatingchunk);
                this.chunkwaiting[indexwatingchunk].status = "Uploading";
                console.log("this.chunkwaiting[i]", this.chunkwaiting[indexwatingchunk]);
                let len_file = Math.ceil(
                  this.showfileupload[i]["allchunk"].length / this.part_per_send
                );
                let result
                if (url !== undefined) {
                  result = await this.makeRequest(
                    "PUT",
                    url,
                    i,
                    numchunk
                  );
                }
                console.log("result", result);
                
                if (result.data.status === 200) {
                  let headers = await this.parseResponseHeaders(result.headers);
                  headers.etag = headers.etag.replace('"', "");
                  headers.etag = headers.etag.replace('"\ ', "");
                  headers.etag = headers.etag.replace('"', "");
                  // this.showfileupload[i]["value"] += Math.ceil(
                  //   100 / (len_file + 1)
                  // );
                  this.showfileupload[i]["value"] > 95 ? this.showfileupload[i]["value"] = 95 : this.showfileupload[i]["value"]
                  let datachunk = {
                    PartNumber: this.showfileupload[i]["allchunk"][numchunk][
                      "PartNumber"
                    ],
                    ETag: headers.etag,
                  };
                  this.showfileupload[i]["parts"].push(datachunk);
                  console.log(
                    "this.showfileupload[i]",
                    this.showfileupload[i]["allchunk"][numchunk]["parts"]
                  );
                  this.showfileupload[i]["allchunk"][numchunk]["status"] =
                    "Success";
                  this.chunkwaiting[indexwatingchunk].status = "Success";
                  this.percentfileuploading = this.showfileupload[i]["value"];
                  let countchunksuccess = this.showfileupload[i][
                    "allchunk"
                  ].filter((item) => item.status === "Success").length;
                  if (
                    countchunksuccess === this.showfileupload[i]["allchunk"].length
                  ) {
                    if (this.showfileupload[i]["status"].code === "Uploading") {
                      await this.fnInsertFileData(datafile, this.showfileupload[i], "chunk");
                      if (this.chunkwaiting.filter((item) => item.status === "Uploading").length == 0) {
                        this.chunkwaiting = [];
                        this.checkopenchunk = false;
                      }
                    }
                  }
                } else {
                  setTimeout(() => {
                    this.fnuploadChunks3(datafile, i, numchunk, "reload")
                  }, 5000);
                  // if (this.count_cancel === 1 || this.showfileupload[objIndex].checkcancelone === true) {
                  //   this.showfileupload[i].status = {
                  //     code: "cencel",
                  //     message: "dragdrop.cannotdetailupload",
                  //     devMessage: "",
                  //   };
                  //   this.chunkwaiting = [];
                  //   this.checkopenchunk = false;
                  //   this.showfileupload[i]["value"] = 100;
                  // } else {
                  //   this.showfileupload[i].status = {
                  //     code: "fail",
                  //     message: "dragdrop.cannotdetailupload",
                  //     devMessage: "",
                  //   };
                  //   this.chunkwaiting = [];
                  //   this.checkopenchunk = false;
                  // }
                }
              }
            } catch (error) {
                console.log(error);
                setTimeout(() => {
                  this.fnuploadChunks3(datafile, i, numchunk, "reload")
                }, 5000);
            }
          }
        }
      } catch (error) {
        console.log("objeerrorct", error);
        this.openclose = true;
        this.status_uploadfail = true;
        this.showfileupload[i].value = 100;
        this.showfileupload[i].color = "red"
        if (this.count_cancel === 1 || this.showfileupload[i].checkcancelone === true) {
          this.showfileupload[i].status = {
            code: "cancel",
            message: "dragdrop.cancelupload",
            devMessage: "",
          };
        } else {
          this.showfileupload[i].status = {
            code: "fail",
            message: "dragdrop.cannotdetailupload",
            devMessage: error,
          };
        }
        this.chunkwaiting = [];
        this.checkopenchunk = false;
      }
    },
    async makeRequest(method, url, i, numchunk) {
      // รับค่า blob data จาก showfileupload
      let data = this.showfileupload[i]["allchunk"][numchunk]["blob"];
      
      // สร้าง XMLHttpRequest object
      const xhr = new XMLHttpRequest();
      const self = this;
  
      // เพิ่ม XMLHttpRequest object นี้ไปใน listxhr ของ showfileupload
      this.showfileupload[i]["listxhr"].push(xhr);

      // คำนวณเปอร์เซ็นต์ที่แต่ละ request จะได้รับ
      const len_file = Math.ceil(this.showfileupload[i]["allchunk"].length / this.part_per_send);
      const chunkProgress = 100 / len_file;

      // ตัวแปรสำหรับเก็บ progress ของ chunk ปัจจุบัน
      let currentProgress = 0;
      
      // Return a new promise
      return new Promise(function(resolve, reject) {
        xhr.open(method, url, true);
        xhr.upload.onprogress = (event) => {
          if (event.lengthComputable) {
            // คำนวณเปอร์เซ็นต์ของ progress
            let percentComplete = (event.loaded / event.total) * chunkProgress;
            
            // อัพเดตค่า self.temparraydataupload[i]["value"] โดยใช้ค่าที่แตกต่างจาก previous progress
            if (self.showfileupload[i]["value"] < 95){
              self.showfileupload[i]["value"] += percentComplete - currentProgress;
            }

            // อัพเดต currentProgress สำหรับ chunk ปัจจุบัน
            currentProgress = percentComplete;
          }
        };
        xhr.onload = function() {
            if (xhr.status >= 200 && xhr.status < 300) {
              let responseHeaders = xhr.getAllResponseHeaders();
              resolve({ headers: responseHeaders, data: xhr });
            } else {
              reject(xhr.statusText);
            }
        };

        xhr.onerror = function() {
          if (xhr.status === 0) {
              // Handle network error (e.g., ERR_INTERNET_DISCONNECTED)
              reject("ERR_INTERNET_DISCONNECTED");
          } else {
              reject(xhr.statusText);
          }
        };

        xhr.onreadystatechange = function() {
          if (xhr.readyState === 4 && xhr.status === 0) {
              // Handle network error (e.g., ERR_INTERNET_DISCONNECTED)
              reject("ERR_INTERNET_DISCONNECTED");
          }
        };
        xhr.send(data);
      });
    },
    parseResponseHeaders(headerString) {
      const headers = {};
      const headerPairs = headerString.split("\r\n");
      headerPairs.forEach(function(headerPair) {
        const index = headerPair.indexOf(":");
        if (index > 0) {
          const key = headerPair.substring(0, index).trim();
          const value = headerPair.substring(index + 1).trim();
          headers[key] = value;
        }
      });
      return headers;
    },
    async fnGetPresignChunk(datafile, i, partnumber) {
      try {
        let payload = {
          bucket_name: datafile.uploadChunk.bucket_name,
          object_name: datafile.uploadChunk.object_name,
          upload_id: datafile.uploadChunk.upload_id,
          part_number: partnumber,
        };
        let auth = await gbfGenerate.generateToken();
        let result = await this.axios({
          method: "POST",
          url:
            process.env.VUE_APP_SERVICE_UPLOAD_API +
            "/api/v1/generate_presigned_url/upload/chunks",
          cancelToken: this.showfileupload[i]["canceltoken"]["token"],
          data: payload,
          headers: { Authorization: auth.code },
          onUploadProgress: (e) => {},
        });
        if (result.data.status === "OK") {
          return result.data.data.url;
        } else if (result.data.errorMessage === "Your space not enough") {
          this.showfileupload[i].status = {
            code: "fail",
            message: "dragdrop.yourspacenotenough",
            devMessage: "",
          };
          this.showfileupload[i].value = 100;
          this.showfileupload[i].color = "error";
          this.chunkwaiting = [];
          this.checkopenchunk = false;
          return "fail";
        } else {
          this.showfileupload[i].status = {
            code: "fail",
            message: "dragdrop.yourspacenotenough",
            devMessage: "",
          };
          this.showfileupload[i].value = 100;
          this.showfileupload[i].color = "error";
          this.chunkwaiting = [];
          this.checkopenchunk = false;
          return "fail";
        }
      } catch (error) {
        if (this.count_cancel === 1 || this.showfileupload[i].checkcancelone === true) {
          this.showfileupload[i].value = 100;
          this.showfileupload[i].color = "red"
          this.openclose = true;
          this.status_uploadfail = true;
          this.checkopenchunk = false;
          this.showfileupload[i].status = {
            code: "cancel",
            message: "dragdrop.cancelupload",
            devMessage: "",
          };
          return "canecl";
        } else {
          // this.showfileupload[i].status = {
          //   code: "fail",
          //   message: "dragdrop.cannotdetailupload",
          //   devMessage: err,
          // };
          return undefined;
        }
        // return "fail";
      }
    },
    async fnUploads3(datafile, item) {
      let objIndex = this.showfileupload.findIndex((obj) => obj.id === item.id);
      try {
        let files = await this.getFile(item.data);
        const formData = new FormData();
        formData.append("policy", datafile.presigned_url.fields.policy);
        formData.append("signature", datafile.presigned_url.fields.signature);
        formData.append("AWSAccessKeyId", datafile.presigned_url.fields.AWSAccessKeyId);
        formData.append("key", datafile.presigned_url.fields.key);
        formData.append("file", files);
        let auth = await gbfGenerate.generateToken();
        let result = await this.axios({
          method: "POST",
          url: datafile.presigned_url.url,
          cancelToken: this.showfileupload[objIndex]["canceltoken"].token,
          data: formData,
          headers: { Authorization: auth.code, "Content-Type": "multipart/form-data" },
          onUploadProgress: (e) => {
            this.showfileupload[objIndex]["status"] = {
              code: "start",
              message: "dragdrop.uploading",
              devMessage: "",
            };
            // เมื่อกดยกเลิกจะไปตกที่ catch
            if ((e.loaded / e.total) * 100 >= 95) {
              this.showfileupload[objIndex]["value"] = 95;
              this.percentfileuploading = this.showfileupload[objIndex][
                "value"
              ];
            } else {
              this.showfileupload[objIndex]["value"] = parseInt(
                (e.loaded / e.total) * 100
              );
              this.percentfileuploading = this.showfileupload[objIndex][
                "value"
              ];
            }
          },
        });
        if (result.status === 204) {
          this.fnInsertFileData(datafile, item, "nochunk");
        } else {
          // this.count_uploadfail++;
          this.openclose = true;
          this.status_uploadfail = true;
          this.stacksizefile =
            this.stacksizefile - this.showfileupload[objIndex]["size"];
          this.showfileupload[objIndex]["value"] = 100;
          this.showfileupload[objIndex]["status"] =
            result.data.errorMessage === "Your space not enough"
              ? {
                  code: "fail",
                  message: "dragdrop.yourspacenotenough",
                  devMessage: "",
                }
              : {
                  code: "fail",
                  message: "dragdrop.cannotdetailupload",
                  devMessage: result.data.errorMessage,
                };
          this.showfileupload[objIndex]["color"] = "red";
          this.percentfileuploading = this.showfileupload[objIndex]["value"];
        }
      } catch (err) {
        console.log(err);
        if (this.count_cancel === 1 || this.showfileupload[objIndex].checkcancelone === true) {
          // this.count_uploadfail++;
          this.status_uploadfail = true;
          this.openclose = true;
          this.showfileupload[objIndex]["value"] = 100;
          this.showfileupload[objIndex]["status"] = {
            code: "cancel",
            message: "dragdrop.cancelupload",
            devMessage: err,
          };
          this.showfileupload[objIndex]["color"] = "red";
          this.percentfileuploading = this.showfileupload[objIndex]["value"];
        } else {
          // this.count_uploadfail++;
          this.status_uploadfail = true;
          this.openclose = true;
          this.stacksizefile =
            this.stacksizefile - this.showfileupload[objIndex]["size"];
          this.showfileupload[objIndex]["value"] = 100;
          this.showfileupload[objIndex]["status"] = {
            code: "fail",
            message: "dragdrop.cannotdetailupload",
            devMessage: err,
          };
          this.showfileupload[objIndex]["color"] = "red";
          this.percentfileuploading = this.showfileupload[objIndex]["value"];
        }
      }
    },
    async fnInsertFileData(datafile, item, status) {
      let objIndex = this.showfileupload.findIndex((obj) => obj.id === item.id);
      try {
        let payload = {
          account_id: datafile.account_id,
          archive_file: datafile.archive_file,
          business_id: datafile.business_id,
          data_type: datafile.data_type,
          department_id: datafile.department_id,
          file_id: datafile.file_id,
          file_id_copied: datafile.file_id_copied,
          filename: datafile.filename,
          folder_id: datafile.folder_id,
          hashtag: datafile.hashtag,
          remark: "",
          size_file: datafile.size_file,
          user_id: datafile.user_id,
          file_duplicate: this.showfileupload[objIndex].file_duplicate,
          folder_from_share: datafile.folder_from_share,
        }
        if (status === "chunk") {
          payload.complete_file_chunk = {
            bucket_name: datafile.uploadChunk.bucket_name,
            object_name: datafile.uploadChunk.object_name,
            upload_id: datafile.uploadChunk.upload_id,
            parts: this.showfileupload[objIndex]["parts"].sort(
              (a, b) => a.PartNumber - b.PartNumber
            ),
          };
        }
        // if (this.$route.name === "directorysharefile") {
        //   payload.folder_from_share =  "Y";
        // }
        let auth = await gbfGenerate.generateToken();
        let result = await this.axios({
          method: "POST",
          url: process.env.VUE_APP_SERVICE_UPLOAD_API + "/api/v1/insert_file_data",
          cancelToken: this.showfileupload[objIndex]["canceltoken"].token,
          data: payload,
          headers: { Authorization: auth.code },
        });
        if (result.data.status === "OK") {
          this.stacksizefile =
            this.stacksizefile - this.showfileupload[objIndex]["size"];
          this.showfileupload[objIndex]["value"] = 100;
          this.showfileupload[objIndex]["status"] = {
            code: "success",
            message: "dragdrop.uploadsuccess",
            devMessage: "",
          };
          this.showfileupload[objIndex]["color"] = "green";
          this.showfileupload[objIndex]["folderid"] = this.parentfolderDD;
          this.percentfileuploading = this.showfileupload[objIndex]["value"];
          if (
            this.percentuploadedallfile === 100 &&
            this.listfileduplicate.length === 0 &&
            this.openclose === false
          ) {
            this.loopsuccess = true;
            this.fn_close_snackbar();
          }
        } else {
          // this.count_uploadfail++;
          this.openclose = true;
          this.status_uploadfail = true;
          this.stacksizefile =
            this.stacksizefile - this.showfileupload[objIndex]["size"];
          this.showfileupload[objIndex]["value"] = 100;
          this.showfileupload[objIndex]["status"] =
            result.data.errorMessage === "Your space not enough"
              ? {
                  code: "fail",
                  message: "dragdrop.yourspacenotenough",
                  devMessage: "",
                }
              : {
                  code: "fail",
                  message: "dragdrop.cannotdetailupload",
                  devMessage: result.data.errorMessage,
                };
          this.showfileupload[objIndex]["color"] = "red";
          this.percentfileuploading = this.showfileupload[objIndex]["value"];
        }
      } catch (err) {
        console.log("err", err);
        if (this.count_cancel === 1 || this.showfileupload[objIndex].checkcancelone === true) {
          // this.count_uploadfail++;
          this.status_uploadfail = true;
          this.openclose = true;
          this.showfileupload[objIndex]["value"] = 100;
          this.showfileupload[objIndex]["status"] = {
            code: "cancel",
            message: "dragdrop.cancelupload",
            devMessage: err,
          };
          this.showfileupload[objIndex]["color"] = "red";
          this.percentfileuploading = this.showfileupload[objIndex]["value"];
        } else {
          // this.count_uploadfail++;
          this.status_uploadfail = true;
          this.openclose = true;
          this.stacksizefile =
            this.stacksizefile - this.showfileupload[objIndex]["size"];
          this.showfileupload[objIndex]["value"] = 100;
          this.showfileupload[objIndex]["status"] = {
            code: "fail",
            message: "dragdrop.cannotdetailupload",
            devMessage: err,
          };
          this.showfileupload[objIndex]["color"] = "red";
          this.percentfileuploading = this.showfileupload[objIndex]["value"];
        }
      }
    },
    // ฟังก์ชันอัปโหลดไฟล์ที่ไม่เป็น chunk
    async fn_uploadfile_nochunk(item) {
      let payload_nochunk;
      let payload_nc = await this.fn_check_payload_file_nochunk(item);
      payload_nochunk = payload_nc.msg;
      let objIndex = this.showfileupload.findIndex((obj) => obj.id === item.id);
      try {
        if (this.$route.name === "directorysharefile") {
          payload_nochunk.append("folder_from_share", "Y");
        }
        let auth = await gbfGenerate.generateToken();
        let result = await this.axios({
          method: "POST",
          url: process.env.VUE_APP_SERVICE_UPLOAD_FILE + "/api/upload_files_v3",
          // cancelToken: this.source.token,
          cancelToken: this.showfileupload[objIndex]["canceltoken"].token,
          data: payload_nochunk,
          headers: { Authorization: auth.code },
          onUploadProgress: (e) => {
            this.showfileupload[objIndex]["status"] = {
              code: "start",
              message: "dragdrop.uploading",
              devMessage: "",
            };
            // เมื่อกดยกเลิกจะไปตกที่ catch
            if ((e.loaded / e.total) * 100 >= 95) {
              this.showfileupload[objIndex]["value"] = 95;
              this.percentfileuploading = this.showfileupload[objIndex][
                "value"
              ];
            } else {
              this.showfileupload[objIndex]["value"] = parseInt(
                (e.loaded / e.total) * 100
              );
              this.percentfileuploading = this.showfileupload[objIndex][
                "value"
              ];
            }
          },
        });
        if (result.data.status === "OK") {
          this.stacksizefile =
            this.stacksizefile - this.showfileupload[objIndex]["size"];
          this.showfileupload[objIndex]["value"] = 100;
          this.showfileupload[objIndex]["status"] = {
            code: "success",
            message: "dragdrop.uploadsuccess",
            devMessage: "",
          };
          this.showfileupload[objIndex]["color"] = "green";
          this.showfileupload[objIndex]["folderid"] = this.parentfolderDD;
          this.percentfileuploading = this.showfileupload[objIndex]["value"];
          // if (
          //   this.percentuploadedallfile === 100 &&
          //   this.listfileduplicate.length === 0 &&
          //   this.openclose === false
          // ) {
          //   this.loopsuccess = true;
          //   this.fn_close_snackbar();
          // }
        } else {
          // this.count_uploadfail++;
          this.openclose = true;
          this.status_uploadfail = true;
          this.stacksizefile =
            this.stacksizefile - this.showfileupload[objIndex]["size"];
          this.showfileupload[objIndex]["value"] = 100;
          this.showfileupload[objIndex]["status"] =
            result.data.errorMessage === "Your space not enough"
              ? {
                  code: "fail",
                  message: "dragdrop.yourspacenotenough",
                  devMessage: "",
                }
              : {
                  code: "fail",
                  message: "dragdrop.cannotdetailupload",
                  devMessage: result.data.errorMessage,
                };
          this.showfileupload[objIndex]["color"] = "red";
          this.percentfileuploading = this.showfileupload[objIndex]["value"];
        }
      } catch (err) {
        if (this.count_cancel === 1 || this.showfileupload[objIndex].checkcancelone === true) {
          // this.count_uploadfail++;
          this.status_uploadfail = true;
          this.openclose = true;
          this.showfileupload[objIndex]["value"] = 100;
          this.showfileupload[objIndex]["status"] = {
            code: "cancel",
            message: "dragdrop.cancelupload",
            devMessage: err,
          };
          this.showfileupload[objIndex]["color"] = "red";
          this.percentfileuploading = this.showfileupload[objIndex]["value"];
        } else {
          // this.count_uploadfail++;
          this.status_uploadfail = true;
          this.openclose = true;
          this.stacksizefile =
            this.stacksizefile - this.showfileupload[objIndex]["size"];
          this.showfileupload[objIndex]["value"] = 100;
          this.showfileupload[objIndex]["status"] = {
            code: "fail",
            message: "dragdrop.cannotdetailupload",
            devMessage: err,
          };
          this.showfileupload[objIndex]["color"] = "red";
          this.percentfileuploading = this.showfileupload[objIndex]["value"];
        }
      }
    },
    async fn_copy_and_paste_files (item) {
      console.log("item file", item)
      var objIndex = this.showfileupload.findIndex((itemFil) => itemFil.id === item.id && !['success', 'fail', 'cancel'].includes(itemFil.status.code) && itemFil.value <= 0)
      console.log("Obkect  ", objIndex)
      let payload = {
        copied: [
            {
                data_type: "file",
                data_id: item.id
            }
        ],
        account_id: this.dataAccountId,
        folder_id: this.toFolder_id === undefined ? this.sendParentfolder() : this.toFolder_id
      }
        let auth = await gbfGenerate.generateToken();
        let result = await this.axios({
          method: "POST",
          url: process.env.VUE_APP_SERVICE_UPLOAD_FILE + "/api/v2/paste_data",
          // cancelToken: this.source.token,
          // cancelToken: this.showfileupload[objIndex]["canceltoken"].token,
          data: payload,
          headers: { Authorization: auth.code },
          onUploadProgress: (e) => {
            this.showfileupload[objIndex]["status"] = {
              code: "start",
              message: "dragdrop.uploading",
              devMessage: "",
            };
            // เมื่อกดยกเลิกจะไปตกที่ catch
            if ((e.loaded / e.total) * 100 >= 95) {
              this.showfileupload[objIndex]["value"] = 95;
              this.percentfileuploading = this.showfileupload[objIndex][
                "value"
              ];
            } else {
              this.showfileupload[objIndex]["value"] = parseInt(
                (e.loaded / e.total) * 100
              );
              this.percentfileuploading = this.showfileupload[objIndex][
                "value"
              ];
            }
          },
        });
        console.log("Resultt copy pastes ", result)
        if (result.data.status === "OK") {
          this.stacksizefile =
            this.stacksizefile - this.showfileupload[objIndex]["size"];
          this.showfileupload[objIndex]["value"] = 100;
          this.showfileupload[objIndex]["status"] = {
            code: "success",
            message: "dragdrop.uploadsuccess",
            devMessage: "",
          };
          this.showfileupload[objIndex]["color"] = "green";
          this.showfileupload[objIndex]["folderid"] = this.parentfolderDD;
          this.percentfileuploading = this.showfileupload[objIndex]["value"];
        }
      },
    // ฟังก์ชันเช็ค payload folder จะเช็คว่าเป็นโฟลเดอร์ root หรือไม่ และเช็คเพื่อหา parentfolder
    fn_check_payload_folder(_item) {
      var payload = {};
      let parent;
      // เช็คว่าเป็น โฟลเดอร์ root หรือไม่
      if (_item.path === "/") {
        parent = this.sendParentfolder();
        var payload = {
          folder_name: _item.name,
          user_id: this.dataUsername,
          id: parent,
          account_id: this.dataAccountId,
          department_id: this.departmentid,
          folder_duplicate: _item["file_duplicate"],
        };
      } else {
        // เช็ค path เพื่อตรวจสอบ parent folder
        for (let i = 0; i < this.showfileupload.length; i++) {
          if (this.showfileupload[i]["type"] === "folder") {
            if (
              _item["path"].substring(0, _item["path"].length - 1) ===
              this.showfileupload[i]["data"].fullPath
            ) {
              this.parentfolderDD = this.showfileupload[i]["folderid"];
            }
          }
        }

        var payload = {
          folder_name: _item.name,
          user_id: this.dataUsername,
          id: this.parentfolderDD,
          account_id: this.dataAccountId,
          department_id: this.departmentid,
        };
      }
      return new Promise((resolve, reject) => {
        resolve({ status: "file", msg: payload });
      });
    },
    // ฟังก์ชันเช็ค payload file ที่ไม่เป็น chunk จะเช็คว่าเป็นไฟล์ที่อยู่ในโฟล์เดอร์ หรือไม่ และเช็คเพื่อหา parentfolder
    async fn_check_payload_file_nochunk(_item) {
      let parent;
      let files;
      this.overmaxsize = false;
      const formData = new FormData();
      try {
        files = await this.getFile(_item.data);
        // if (files.size >= 104857600) {
        //   this.overmaxsize = true;
        // }
        // เช็คว่าเป็นไฟล์ที่อยู่ในโฟล์เดอร์หรือไม่
        if (_item.path === "/") {
          parent = this.sendParentfolder();
          formData.append("file", files);
          formData.append("file_name", files.name);
          formData.append("size_file", files.size);
          formData.append("folder_id", parent);
          formData.append("user_id", this.dataUsername);
          formData.append("department_id", this.departmentid);
          formData.append("account_id", this.dataAccountId);
          formData.append("file_duplicate", _item["file_duplicate"]);
          formData.append("remark", "");
          formData.append("old_version_file_id", "");
          formData.append(`status_encrypt`, "N");
        } else {
          let folderid
          for (let i = 0; i < this.showfileupload.length; i++) {
            if (this.showfileupload[i]["type"] === "folder") {
              if (
                _item["path"].substring(0, _item["path"].length - 1) ===
                this.showfileupload[i]["data"].fullPath
              ) {
                folderid = this.showfileupload[i]["folderid"];
              }
            }
          }
          formData.append("file", files);
          formData.append("file_name", files.name);
          formData.append("size_file", files.size);
          formData.append("folder_id", folderid);
          formData.append("user_id", this.dataUsername);
          formData.append("department_id", this.departmentid);
          formData.append("account_id", this.dataAccountId);
          formData.append("file_duplicate", _item["file_duplicate"]);
          formData.append("remark", "");
          formData.append("old_version_file_id", "");
          formData.append(`status_encrypt`, "N");
        }
        return new Promise((resolve, reject) => {
          resolve({ status: "file", msg: formData });
        });
      } catch (ex) {
        console.log(ex);
      }
    },
    // ฟังก์ชันเช็ค payload merge file chunk จะเช็คว่าเป็นไฟล์ที่อยู่ในโฟล์เดอร์ หรือไม่ และเช็คเพื่อหา parentfolder
    async fn_check_payload_file_merge(_item) {
      let parent;
      let files;
      let objIndex = this.showfileupload.findIndex(
        (obj) => obj.id === _item.id
      );
      const formData = new FormData();
      try {
        files = await this.getFile(_item.data);
        if (_item.path === "/") {
          parent = this.sendParentfolder();

          formData.append(`account_id`, this.dataAccountId);
          formData.append(`user_id`, this.dataUsername);
          formData.append(`folder_id`, parent);
          formData.append(
            `file_duplicate`,
            _item["file_duplicate"]
          );
          formData.append(`file_name`, files.name);
          formData.append(`remark`, "");
          formData.append(
            `len_file`,
            Math.ceil(this.chunks.length / this.part_per_send)
          );
          formData.append(`key`, this.showfileupload[objIndex]["chunkkey"]);
        } else {
          for (let i = 0; i < this.showfileupload.length; i++) {
            if (this.showfileupload[i]["type"] === "folder") {
              if (
                _item["path"].substring(0, _item["path"].length - 1) ===
                this.showfileupload[i]["data"].fullPath
              ) {
                this.parentfolderDD = this.showfileupload[i]["folderid"];
              }
            }
          }

          formData.append(`account_id`, this.dataAccountId);
          formData.append(`user_id`, this.dataUsername);
          formData.append(`folder_id`, this.parentfolderDD);
          formData.append(
            `file_duplicate`,
            _item["file_duplicate"]
          );
          formData.append(`file_name`, files.name);
          formData.append(`remark`, "");
          formData.append(
            `len_file`,
            Math.ceil(this.chunks.length / this.part_per_send)
          );
          formData.append(`key`, this.showfileupload[objIndex]["chunkkey"]);
        }
        return new Promise((resolve, reject) => {
          resolve({ status: "file", msg: formData });
        });
      } catch (ex) {
        console.log(ex);
      }
    },
    // ฟังก์ชันเช็ค payload file chunk จะเช็คว่าเป็นไฟล์ที่อยู่ในโฟล์เดอร์ หรือไม่ และเช็คเพื่อหา parentfolder
    async fn_check_payload_file(_item) {
      let parent;
      let files;
      try {
        const formData = new FormData();
        files = await this.getFile(_item.data);
        console.log("file_part", files.name + `.part_${this.loop}`);
        if (_item.path === "/") {
          parent = this.sendParentfolder();
          formData.append(`account_id`, this.dataAccountId);
          formData.append(`folder_id`, parent);
          formData.append(`size_file`, _item["size"]);
          formData.append(`file_part`, files.name + `.part_${this.loop}`);
          formData.append(`key`, _item["chunkkey"]);
          formData.append(`file_duplicate`, _item["file_duplicate"]);
          formData.append(`file_name`, files.name);
          for (let i = 0; i < this.part_per_send; i++) {
            if (this.chunks[i + this.loop * this.part_per_send]) {
              formData.append(`blob`, this.chunks[i + this.loop]);
            }
          }
          for (const value of formData.values()) {
            console.log(value);
          }
        } else {
          for (let i = 0; i < this.showfileupload.length; i++) {
            if (_item["type"] === "folder") {
              if (
                _item["path"].substring(0, _item["path"].length - 1) ===
                _item["data"].fullPath
              ) {
                this.parentfolderDD = _item["folderid"];
              }
            }
          }
          formData.append(`account_id`, this.dataAccountId);
          formData.append(`folder_id`, this.parentfolderDD);
          formData.append(`size_file`, files.size);
          formData.append(`file_part`, files.name + `.part_${this.loop}`);
          formData.append(`key`, _item["chunkkey"]);
          formData.append(`file_duplicate`, _item["file_duplicate"]);
          formData.append(`file_name`, files.name);
          for (let i = 0; i < this.part_per_send; i++) {
            if (this.chunks[i + this.loop * this.part_per_send]) {
              formData.append(`blob`, this.chunks[i + this.loop]);
            }
          }
        }
        return new Promise((resolve) => {
          resolve({ status: "file", msg: formData });
        });
      } catch (ex) {
        console.log(ex);
      }
    },
    fn_close_snackbar() {
      if (this.listfileduplicate.length > 0) {
        this.openalertduplicatefile = true;
      } else if (this.length_processing === this.showfileupload.length) {
        this.btnClosedetail = true;
        this.openclose = true
        setTimeout(() => {
          this.forceDestroyEvent(false);
          this.$emit("loadfile");
          this.$emit("status_closedragzone");
          this.conutindexupload = 0;
          if (this.$route.name !== "directorysharefile") {
            this.$store.dispatch("check_storage", this.dataAccountId);
          }
        }, 1500);
      }
    },
    // ฟังก์ชันสร้าง chunk key
    generate_uuid(i) {
      // console.log(item);
      // let objIndex = this.showfileupload.findIndex((obj) => obj.id === item.id);
      let myuuid = "";
      myuuid = uuidv4();
      this.showfileupload[i]["chunkkey"] = myuuid;
    },
    // ฟังก์ชันสร้าง list chunk
    async create_chunk(_item) {
      this.chunks = [];
      // var size = 10485760;
      var size = 52428800;
      var chunks = 0;
      let file;
      try {
        let res = await this.getFile(_item.data);
        file = res;
        chunks = Math.ceil(file.size / size);
        for (let i = 0; i < chunks; i++) {
          this.chunks.push(
            file.slice(
              i * size,
              Math.min(i * size + size, file.size),
              file.type
            )
          );
        }
      } catch (ex) {
        console.log(ex);
      }
    },
    async create_chunk_new(item) {
      var size = 1073741824;
      // var size = 104857600;
      let file = await this.getFile(item.data);
      var chunks = 0;
      chunks = Math.ceil(item.size / size);
      for (let i = 0; i < chunks; i++) {
        let payload_blob = {
          status: "start",
          blob: file.slice(
            i * size,
            Math.min(i * size + size, item.size)
            // "text/plain"
          ),
          PartNumber: i + 1,
          parts: {},
          id: item.id
        };
        item.allchunk.push(payload_blob);
      }
    },
    // ฟังก์ชันเปิดรายละเอียด
    detailuploadclick(e) {
      e.preventDefault();
      this.x = e.clientX;
      this.y = e.clientY;
      this.opendetailupload = true;
      // this.snackbar = false;
    },
    // ฟังก์ชันปิด snackbar และ รายละเอียด
    closedetail() {
      this.opendetailupload = false;
      this.snackbar = false;
      this.openclose = false;
      this.foldername_upload = "";
      this.forceDestroyEvent(false);
      this.fn_clear_before_upload();
      this.showfileupload = [];
      this.countKeyfolder = 0;
      this.entryTotal = 0;
      this.datachunkwaiting = [];
      this.chunkwaiting = [];
      this.checkopenchunk = false;
      this.conutindexupload = 0;
      if (this.count_cancel > 0 || this.count_uploadfail > 0) {
        if (this.$route.name !== "directorysharefile") {
          this.$store.dispatch("check_storage", this.dataAccountId);
        }
        // this.$emit("loadfile");
        this.$emit("status_closedragzone");
      }
    },
    // ฟังก์ชันสลับจาก รายละเอียด ไป snackbar
    hidedetail() {
      this.opendetailupload = false;
      // this.snackbar = true;
    },
    // ฟังก์ชันเช็ค folder id ของที่อยู่ปัจจุบัน
    sendParentfolder() {
      // Tag mai
      if (this.$route.params.id === undefined) {
        if (this.opendragselected === true) {
          this.parentfolder =  this.currentfolderid
        } else {
          if (!!this.parentFolder_drop) {
            this.parentfolder = this.parentFolder_drop;
          } else {
            this.parentfolder = this.account_active["directory_id"];
          }
        }
      } else {
        if (this.opendragselected === true) {
          this.parentfolder =  this.currentfolderid
        } else {
          if (!!this.parentFolder_drop) {
            this.parentfolder = this.parentFolder_drop;
          } else {
            this.parentfolder = this.$route.params.id;
          }
        }
      }
      return this.parentfolder;
      //  this.parentfolder = this.account_active["directory_id"];
      // console.log(this.parentfolder);
    },
  },
  mounted() {
    this.$router.beforeResolve((to, from, next) => {
      console.log("tototototo tototototo", to.name);
      console.log("from tototototo", from.name);
      if (this.snackbar === true) {
        if (this.percentuploadedallfile === 100 && this.listfileduplicate.length === 0) {
          if (to.name !== from.name) {
            this.removeEvent();
            this.forceDestroyEvent(false);
            console.log("from toxxxxxxxxxxxxxxxxxxtotototo", from.name);
          }
          next()
        } else {
          if (this.$t("default") === "th") {
            let txt;
            if (confirm("ระบบอาจไม่ได้บันทึกการเปลี่ยนแปลงของคุณ")) {
              txt = "ตกลง";
              if (to.name !== from.name) {
                this.removeEvent();
                this.forceDestroyEvent(false);
                console.log("from totxxxxxxxxxxxxxxxxxotototo", from.name);
              }
              this.snackbar = false;
              next()
            } else {
              txt = "ยกเลิก";
            }
            document.getElementById("demo").innerHTML = txt;
          } else {
            let txt;
            if (confirm("Changes you made may not be saved.")) {
              txt = "Submit";
              if (to.name !== from.name) {
                this.removeEvent();
                this.forceDestroyEvent(false);
                console.log("from txxxxxxxxxxxxxxxototototo", from.name);
              }
              this.snackbar = false;
              next()
            } else {
              txt = "Cancel";
            }
            document.getElementById("demo").innerHTML = txt;
          }
        }
      } else {
        if (to.name !== from.name) {
          this.removeEvent();
          this.forceDestroyEvent(false);
          console.log("fedffffffffffxxxxxxxxxxxxxxxxxo", from.name);
        }
        next()
      }
    });
  },
  beforeDestroy() {
    this.removeEvent();
  },
};
</script>
<style>
.v-snack__content {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}
div#textnode {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  transition: font-size 175ms;
}
.fixed-dialog {
  bottom: 40px;
  position: fixed;
  z-index: 5;
}
/* div#dropzone {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999999999;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: visibility 175ms, opacity 175ms;
    display: table;
    text-shadow: 1px 1px 2px #000;
    color: #fff;
    background: rgba(0, 0, 0, 0.45);
    font: bold 42px Oswald, DejaVu Sans, Tahoma, sans-serif;
  }
  
  #dropzone {
    min-height: 100%;
  } */
div#dropzonetest {
  height: 100%;
  position: absolute;
  width: 100%;
  transition: visibility 175ms, opacity 175ms;
  display: table;
  text-shadow: 1px 1px 2px #000;
  color: #fff;
  font: bold 42px Oswald, DejaVu Sans, Tahoma, sans-serif;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>